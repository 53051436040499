import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import Button from "../../../student/components/generic/button";
import { Experiment } from "./types";
import { sum } from "lodash";
import { TableCell, TableHead } from "./Table";
import { TextInput } from "./TextInput";
import { SelectInput } from "./SelectInput";
import { InputError } from "./InputError";
import { RemoveItemButton } from "./RemoveItemButton";
import { AddItemButton } from "./AddItemButton";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { EXPERIMENT_ITEM, EXPERIMENTS_LIST } from "./queries";

type FormInputs = Pick<Experiment, "variants" | "overrides">;

export const EditExperimentForm: React.FC<{ experiment: Experiment }> = ({
  experiment,
}) => {
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: experiment,
  });
  const {
    fields: variantFields,
    append: appendVariant,
    remove: removeVariant,
  } = useFieldArray({
    control,
    name: "variants",
  });
  const {
    fields: overrideFields,
    append: appendOverride,
    remove: removeOverride,
  } = useFieldArray({
    control,
    name: "overrides",
  });

  const isVariantsEditable = experiment.state === "draft";
  const isOverridesEditable =
    experiment.state === "draft" || experiment.state === "open";

  const { mutateAsync: editExperimentMutation, isLoading } = useMutation(
    (data: FormInputs) =>
      axios.post(
        `${deltamathAPI()}/experiments/edit/details`,
        JSON.stringify({ experimentId: experiment._id, ...data }),
        withJsonHeader()
      )
  );

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    await editExperimentMutation(data);
    await queryClient.invalidateQueries(EXPERIMENTS_LIST);
    await queryClient.invalidateQueries(EXPERIMENT_ITEM);
    toastContext.addToast({ message: "Experiment saved", status: "Success" });
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h3 className="text-lg font-bold">Variants</h3>
        <p className="mb-2 text-sm text-dm-charcoal-500">
          These are the different branches for this experiment.
        </p>
        <div className="flex flex-col items-start gap-2 rounded border border-dm-charcoal-200 bg-white/50 px-6 py-4">
          <table>
            {variantFields.length > 0 && (
              <TableHead
                cols={[
                  "Name",
                  "Weight",
                  ...(isVariantsEditable
                    ? [""]
                    : experiment.state === "closed" ||
                      experiment.state === "retired"
                    ? ["Winning variant"]
                    : []),
                ]}
              />
            )}
            <tbody>
              {variantFields.map((item, index) => (
                <tr key={item.id}>
                  <TableCell>
                    <TextInput
                      type="text"
                      name={`variants.${index}.name`}
                      register={register}
                      validation={{
                        required: "You must provide a variant name",
                        validate: (value) => {
                          const variants = getValues("variants");
                          if (variants.length < 2) {
                            return "You need at least two variants";
                          }
                          if (
                            variants.filter((v) => v.name === value).length > 1
                          ) {
                            return "Variant names must be unique";
                          }
                        },
                      }}
                      disabled={!isVariantsEditable}
                    />
                    {errors.variants &&
                      errors.variants[index] &&
                      errors.variants[index]?.name && (
                        <InputError>
                          {errors.variants[index]?.name?.message}
                        </InputError>
                      )}
                  </TableCell>
                  <TableCell>
                    <TextInput
                      type="number"
                      name={`variants.${index}.weight`}
                      register={register}
                      validation={{
                        min: 0.01,
                        max: 0.99,
                        valueAsNumber: true,
                        required: "You must provide a variant weight",
                        validate: () => {
                          const weights = getValues("variants").map(
                            (v) => v.weight
                          );
                          return sum(weights) !== 1
                            ? "The sum of all weights must be 1"
                            : undefined;
                        },
                      }}
                      step={0.01}
                      disabled={!isVariantsEditable}
                    />
                    {errors.variants &&
                      errors.variants[index] &&
                      errors.variants[index]?.weight && (
                        <InputError>
                          {errors.variants[index]?.weight?.message}
                        </InputError>
                      )}
                  </TableCell>
                  {isVariantsEditable && (
                    <TableCell>
                      <RemoveItemButton onClick={() => removeVariant(index)} />
                    </TableCell>
                  )}
                  {(experiment.state === "closed" ||
                    experiment.state === "retired") && (
                    <TableCell>
                      {item.name === experiment.winningVariant ? (
                        <i className="fas fa-award ml-2 mt-3" />
                      ) : (
                        ""
                      )}
                    </TableCell>
                  )}
                </tr>
              ))}
              {variantFields.length === 0 && (
                <tr>
                  <td className="italic" colSpan={3}>
                    No variants yet!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isVariantsEditable && (
            <AddItemButton
              onClick={() => appendVariant({ name: "newVariant", weight: 0.5 })}
            >
              Add a variant
            </AddItemButton>
          )}
        </div>
      </div>

      <div>
        <h3 className="text-lg font-bold">Overrides</h3>
        <p className="mb-2 text-sm text-dm-charcoal-500">
          Use overrides to put a specific user into a variant. Use{" "}
          <code>userId</code> for logged in users and <code>token</code> for
          logged out users.
        </p>
        <div className="flex flex-col items-start gap-2 rounded border border-dm-charcoal-200 bg-white/50 px-6 py-4">
          <table>
            {overrideFields.length > 0 && (
              <TableHead
                cols={[
                  "User ID",
                  "Experiment token",
                  "Variant",
                  ...(isOverridesEditable ? [""] : []),
                ]}
              />
            )}
            <tbody>
              {overrideFields.map((item, index) => (
                <tr key={item.id}>
                  <TableCell>
                    <TextInput
                      name={`overrides.${index}.userId`}
                      register={register}
                      type="text"
                      disabled={!isOverridesEditable}
                      validation={{
                        validate: (value) => {
                          const overrides = getValues("overrides");
                          const thisOverride = overrides[index];
                          if (
                            typeof value !== "string" ||
                            typeof thisOverride.token !== "string"
                          ) {
                            return;
                          }
                          if (
                            value.length > 0 &&
                            overrides.filter((o) => o.userId === value).length >
                              1
                          ) {
                            return "User IDs must be unique";
                          }
                          if (
                            value.length === 0 &&
                            thisOverride.token.length === 0
                          ) {
                            return "You must provide either a user ID or a token";
                          }
                          if (
                            value.length > 0 &&
                            thisOverride.token.length > 0
                          ) {
                            return "You must provide either a user ID or a token, not both";
                          }
                        },
                      }}
                    />
                    {errors.overrides &&
                      errors.overrides[index] &&
                      errors.overrides[index]?.userId && (
                        <InputError>
                          {errors.overrides[index]?.userId?.message}
                        </InputError>
                      )}
                  </TableCell>
                  <TableCell>
                    <TextInput
                      name={`overrides.${index}.token`}
                      register={register}
                      type="text"
                      disabled={!isOverridesEditable}
                      validation={{
                        validate: (value) => {
                          const overrides = getValues("overrides");
                          const thisOverride = overrides[index];
                          if (
                            typeof value !== "string" ||
                            typeof thisOverride.userId !== "string"
                          ) {
                            return;
                          }
                          if (
                            typeof value === "string" &&
                            value.length > 0 &&
                            overrides.filter((o) => o.token === value).length >
                              1
                          ) {
                            return "Tokens must be unique";
                          }
                          if (
                            value.length === 0 &&
                            thisOverride.userId.length === 0
                          ) {
                            return "You must provide either a user ID or a token";
                          }
                          if (
                            value.length > 0 &&
                            thisOverride.userId.length > 0
                          ) {
                            return "You must provide either a user ID or a token, not both";
                          }
                        },
                      }}
                    />
                    {errors.overrides &&
                      errors.overrides[index] &&
                      errors.overrides[index]?.token && (
                        <InputError>
                          {errors.overrides[index]?.token?.message}
                        </InputError>
                      )}
                  </TableCell>
                  <TableCell>
                    <SelectInput
                      name={`overrides.${index}.variant`}
                      register={register}
                      disabled={!isOverridesEditable}
                      validation={{ required: "You must select a variant" }}
                      options={getValues("variants").map((v) => v.name)}
                    />
                    {errors.overrides &&
                      errors.overrides[index] &&
                      errors.overrides[index]?.variant && (
                        <InputError>
                          {errors.overrides[index]?.variant?.message}
                        </InputError>
                      )}
                  </TableCell>
                  {isOverridesEditable && (
                    <TableCell>
                      <RemoveItemButton onClick={() => removeOverride(index)} />
                    </TableCell>
                  )}
                </tr>
              ))}
              {overrideFields.length === 0 && (
                <tr>
                  <td colSpan={3}>
                    <span className="block italic text-dm-charcoal-500">
                      No overrides yet!
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isOverridesEditable && (
            <AddItemButton
              onClick={() =>
                appendOverride({ userId: "", token: "", variant: "" })
              }
            >
              Add an override
            </AddItemButton>
          )}
        </div>
      </div>
      <div>
        <Button isLoading={isLoading}>Save experiment</Button>
      </div>
    </form>
  );
};
