import { Link, NavLink, useLocation } from "react-router-dom";
import {
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_LEARNER_LINK,
  getFilePath,
} from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { UserWarningIcon } from "./UserWarningIcon";
import { useParentContext } from "../../contexts/ParentContext";
import { InsightsContainer } from "./InsightsContainer";
import { useDeltaMathHomeLink } from "../../utils/useDeltaMathHomeLink";
import clsx from "clsx";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { useResetExperiments } from "../../utils/experiments/useResetExperiments";
import { ProfileIcon } from "../Sidebar/ProfileIcon";

export const ParentNav: React.FC = () => {
  const userContext = useUserContext();
  const learnerContext = useLearnerContext();
  const { learners } = useParentContext();
  const deltaMathHomeLink = useDeltaMathHomeLink();
  const { pathname } = useLocation();
  const isProfileActive = pathname.includes("profile");
  const { reset: resetAnalytics } = useLearnerAnalytics();
  const resetExperiments = useResetExperiments();

  return (
    <nav className="sticky inset-x-0 top-0 z-20 flex items-center justify-between gap-x-5 border-b border-dm-charcoal-100 bg-white px-4 py-3">
      <div className="flex items-center gap-6 lg:gap-[120px]">
        <Link
          to={deltaMathHomeLink}
          className="h-9 bg-white focus:outline-none"
        >
          <ResponsiveImage
            className="z-2 transition-width h-9 duration-300 ease-out"
            srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
            alt="DeltaMath Home"
          />
        </Link>
        <InsightsContainer />
      </div>
      <div className="flex items-center gap-6">
        <NavLink
          className={clsx(
            "flex items-center gap-2 rounded-[4px] border px-4 py-2 text-[14px] leading-[26px]",
            isProfileActive
              ? "border-dm-brand-blue-100 bg-dm-brand-blue-100 font-bold hover:border-dm-brand-blue-200"
              : "border-transparent"
          )}
          to={`${REACT_APP_LEARNER_LINK}/parent/profile`}
        >
          <ProfileIcon
            className="relative"
            isProfileActive={isProfileActive}
            needsLearnerSetUp={
              (learnerContext.learner?.subscriptionQuantity ?? 0) >
              learners.length
            }
          />
          {learnerContext.learner ? (
            <span>
              {learnerContext.learner?.first} {learnerContext.learner?.last}
            </span>
          ) : (
            <span className="text-transparent">Loading user info...</span>
          )}
        </NavLink>
        {userContext.state.decodedJwt?.data?.decampTarget === undefined && (
          <button
            className="align-middle text-sm text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70"
            onClick={async () => {
              learnerContext.logoutLearner();
              resetAnalytics();
              await resetExperiments();
              window.location.href = `${REACT_APP_HOMEPAGE_LINK}/home`;
            }}
            aria-label="log out"
          >
            Log Out
          </button>
        )}
      </div>
    </nav>
  );
};
