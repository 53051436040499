import { useDocumentTitle } from "usehooks-ts";
import { BasicContentContainer } from "../BasicContentContainer";
import { DmLoadingSpinner } from "../../utils/functions";
import { CreateExperimentForm } from "./CreateExperimentForm";
import { ExperimentsTable } from "./ExperimentsTable";
import { useExperimentsListQuery } from "./queries";

export const ExperimentsPage: React.FC = () => {
  useDocumentTitle("Experiments");

  const {
    data: experimentData,
    isLoading,
    isError,
  } = useExperimentsListQuery();

  return (
    <div className="flex flex-col gap-10 p-6">
      <div>
        <h1 className="mb-1 text-2xl font-bold">Experiments</h1>
      </div>
      <div>
        <h2 className="mb-2 text-lg font-bold">Create a new experiment</h2>
        <CreateExperimentForm />
      </div>
      {isError ? (
        <BasicContentContainer>
          <p className="text-dm-error-500">
            There was a problem fetching experiment data
          </p>
        </BasicContentContainer>
      ) : isLoading ? (
        <BasicContentContainer>
          <DmLoadingSpinner message="" />
        </BasicContentContainer>
      ) : (
        experimentData && (
          <ExperimentsTable experiments={experimentData.experiments} />
        )
      )}
    </div>
  );
};
