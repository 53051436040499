import { useLearnerContext } from "../../contexts/LearnerContext";
import { UserWarningIcon } from "../Parent/UserWarningIcon";

export const ProfileIcon: React.FC<{
  className?: string;
  isProfileActive?: boolean;
  needsLearnerSetUp?: boolean;
}> = ({ className, isProfileActive = false, needsLearnerSetUp = false }) => {
  const { learner } = useLearnerContext();

  return (
    <span className={className} aria-hidden>
      {learner?.subscriptionPaymentFail ? (
        <UserWarningIcon
          maskClassName={
            isProfileActive ? "fill-dm-brand-blue-100" : "fill-white"
          }
          stroke="#D21527"
        />
      ) : needsLearnerSetUp ? (
        <UserWarningIcon
          maskClassName={
            isProfileActive ? "fill-dm-brand-blue-100" : "fill-white"
          }
          stroke="#D3A605"
        />
      ) : (
        <i className="far fa-user" />
      )}
    </span>
  );
};
