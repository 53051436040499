import axios from "axios";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useUserContext } from "../../shared/contexts/UserContext";
import { deltamathAPI } from "../utils";
import { ChangeEvent } from "react";
import { REACT_APP_IMPERSONATION_LINK } from "../../utils";

interface ImpersonateProps {
  type?: string;
  teachercode: string;
  className?: string;
}

const Impersonate: React.FC<ImpersonateProps> = ({
  type = "teacher-as-staff",
  teachercode,
  className = "",
}) => {
  const userContext = useUserContext();
  const toastContext = useDeltaToastContext();

  const jwt = userContext.getJWT();

  //TODO: when the new teacher app is ready, we will want to use something like this to get the impersonation token. For now, it seems that routing through the angular impersonation service makes it impossible to store the token in state in a way that will persist through the redirect. Relying on local storage seems like the way to go for now.
  const getImpersonationToken = async () => {
    try {
      const res = await axios.get(
        `${deltamathAPI()}/impersonate/teacher-as-staff/${teachercode}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (res?.status !== 200) {
        throw new Error("Response Error");
      }
    } catch (err) {
      console.error(err);
      toastContext.addToast({
        status: "Error",
        message: `Failed to impersonate teacher. Check console for more information.`,
      });
    }
  };

  const handleImpersonate = () => {
    //  await getImpersonationToken();
    localStorage.setItem("managerToken", jwt);
    // window.location.href = `http://localhost:4200/impersonate?type=${type}&id=${teachercode}`; //(local testing)
    window.location.href = `${REACT_APP_IMPERSONATION_LINK}?type=${type}&id=${teachercode}`;
  };

  return (
    <button className={`${className}`} onClick={handleImpersonate}>
      Impersonate Teacher
    </button>
  );
};

export default Impersonate;
