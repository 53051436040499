import { AddLearnersHeader } from "./AddLearnersHeader";
import { AddLearnersOutro } from "./AddLearnersOutro";
import { EmptyLearnerPage } from "../../Layouts/EmptyLearnerPage";

export const AddLearnersOutroPage: React.FC = () => {
  return (
    <EmptyLearnerPage title="Add Learners" dontTrackTime>
      <main className="flex h-dvh w-dvw flex-col items-center">
        <AddLearnersHeader showSkipLink={false} />

        <AddLearnersOutro />
      </main>
    </EmptyLearnerPage>
  );
};
