import { DmLoadingSpinner } from "../../../../manager/utils/functions";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import { AddLearnersHeader } from "./AddLearnersHeader";
import { AddLearnersForm } from "./AddLearnersForm";
import { EmptyLearnerPage } from "../../Layouts/EmptyLearnerPage";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../../utils/useSubscription";

export const AddLearnersPage: React.FC = () => {
  const navigate = useNavigate();
  const { subscription, isLoading } = useSubscription();
  const numLearners = subscription?.quantity ?? 0;

  if (isLoading) {
    return (
      <main className="flex h-dvh w-dvw items-center justify-center">
        <DmLoadingSpinner message="" />
      </main>
    );
  }

  return (
    <EmptyLearnerPage title="Add Learners" dontTrackTime>
      <main className="flex h-dvh w-dvw flex-col items-center">
        <AddLearnersHeader
          title={numLearners > 1 ? "Add Learners" : undefined}
          showSkipLink
        />

        <AddLearnersForm
          numLearners={numLearners}
          next={() =>
            navigate(`${REACT_APP_LEARNER_LINK}/parent/learners/done`)
          }
        />
      </main>
    </EmptyLearnerPage>
  );
};
