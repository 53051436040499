import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Link, Route, Routes, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import Cookies from "js-cookie";
import DistrictIndex from "./district/Index";
import DistrictAdministrators from "./district/DistrictAdministrators";
import AddSchool from "./district/AddSchool";
import School from "./schools/School";
import SchoolsIndex from "./schools/Index";
import Teacher from "./teachers/Teacher";
import Assignment from "./assignment/Index";
import PushAssignment from "./push-assignment/Index";
import ViewPushedAssignments from "./push-assignment/View";
import Profile from "./profile/Index";
import Login from "../manager/components/Login";
import {
  isRequestFromLocal,
  isRequestFromReviewapp,
  useDMQuery,
  isRequestFromDev,
  REACT_APP_ADMIN_LINK,
  REACT_APP_HOMEPAGE_LINK,
  executeQuery,
} from "../utils";
import { MenuAlt1Icon, XIcon } from "@heroicons/react/outline";
import SideNavigation from "./SideNavigation";
import ErrorBoundary from "../shared/ErrorBoundary";
import { getAdminParams } from "./utils";
import { isTokenExpired, deltamathLogo } from "../utils";
import HelpVideos from "./help-videos/Index";
import AdminFeatureContext from "./_context/FeatureContext";

import { useUserContext } from "../shared/contexts/UserContext";
import StudentPerformanceContainer from "./reports/student-performance/Index";
import StudentUsageContainer from "./reports/student-usage/Index";
import IntegralTrialBanner from "../manager/components/IntegralTrialBanner";
import { RenewalBanner } from "./RenewalBanner";
import { deltamathAPI } from "../manager/utils";
import axios from "axios";
import ImpersonationHeader from "../shared/ImpersonationHeader";

export default function AdminApplication() {
  const params = useParams();
  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const adminParams = getAdminParams();
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(false);
  const [schools, setSchools] = useState<any>([]);
  const [hasIntegralAtAll, setHasIntegralAtAll] = useState(false);
  const [schoolsWithIntegral, setSchoolsWithIntegral] = useState<any>([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [demoModeEnabled, setDemoModeEnabled] = React.useState(
    localStorage.getItem("demo-mode") === "true"
  );
  const featureRenewalBanner = true;

  /**
   * we can toggle this using the customer_service_token to review before going live.
   * for now, just leaving as false
   */
  const [useAdminStyles, setUseAdminStyles] = useState(true);
  useEffect(() => {
    if (params["*"]?.indexOf("reports") === -1) {
      localStorage.removeItem("usagePreferences");
    }
  }, [params]);

  React.useEffect(() => {
    localStorage.setItem("demo-mode", demoModeEnabled.toString());
  }, [demoModeEnabled]);

  const { data: adminsData, refetch: adminsRefetch } = useDMQuery({
    path: "/admin_new/data/admins",
    params: adminParams,
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  });

  const {
    data: schoolData,
    isSuccess: schoolIsSuccess,
    refetch: schoolRefetch,
    isFetched: isSchoolDataFetched,
  } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  });

  const {
    data: districtData,
    isSuccess: districtIsSuccess,
    refetch: districtDataRefetch,
    isFetched: isDistrictDataFetched,
  } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false, // We only want to make this request if we're a district admin, we check that in a useEffect below
    },
  });

  const prefetchSections = async () => {
    await queryClient.prefetchQuery("/admin_new/data/sections", async () => {
      const data = await executeQuery({
        path: `/admin_new/data/sections`,
        method: "get",
      });
      return data;
    });
  };

  const { mutate: updateAdminPortalView } = useMutation(() => {
    return axios.post(deltamathAPI() + "/admin_new/user/last-portal-view", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.getJWT()};`,
      },
    });
  });

  useEffect(() => {
    if (loggedIn) {
      prefetchSections();
      updateAdminPortalView();
    }
  }, [loggedIn]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const id = user.admin ? user.admin : user._id;
    if (adminsData && adminsData.length > 0) {
      const findCurrentUser = adminsData.filter(
        (user: { _id: string }) => user._id === id
      );
      if (findCurrentUser && findCurrentUser.length > 0) {
        districtDataRefetch();
      }
    }
  }, [adminsData]);

  useEffect(() => {
    const schoolLicense: {
      schoolid: string;
      licenseTier: string;
      schoolName: string;
    }[] = [];
    if (schoolData && Array.isArray(schoolData)) {
      schoolData.forEach(
        (school: { schoolid: string; schoolName: string; dmLicense: any }) => {
          // If they are in the admin portal, it's at least a plus license
          let licenseTier = "plus";

          // There's a case where we have schools with integral, but they don't get the admin portal
          // Check for both district and school cases
          if (
            districtData?.dmLicense?.hide_admin_portal !== true &&
            districtData?.dmLicense?.has_integral
          ) {
            licenseTier = "integral";
          } else if (
            school?.dmLicense?.hide_admin_portal !== true &&
            school?.dmLicense?.has_integral
          ) {
            licenseTier = "integral";
          } else if (
            districtData?.dmLicense?.force_show_admin_portal ||
            school?.dmLicense?.force_show_admin_portal
          ) {
            licenseTier = "integral";
          }
          return schoolLicense.push({
            ...school,
            licenseTier,
          });
        }
      );
    }
    const sortedSchools = schoolLicense.sort(function (
      a: { schoolName: string },
      b: { schoolName: string }
    ) {
      const textA = a.schoolName.toUpperCase();
      const textB = b.schoolName.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setSchools(sortedSchools);
    setSchoolsWithIntegral(
      schoolLicense.filter((l: any) => l.licenseTier === "integral")
    );
    setHasIntegralAtAll(
      schoolLicense.some((e: any) => e.licenseTier === "integral")
        ? true
        : false
    );
  }, [schoolData, districtData]);

  useEffect(() => {
    document.title = "DeltaMath Admin Data Portal";
    document.body.classList.add("h-full");
    const root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
    root.setAttribute("class", "h-full bg-white");
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries();
    const jwt = userContext.getJWT();
    const refresh_token_cookie = Cookies.get("refresh_token_javascript");

    // User has non-expired JWT or refresh token cookie
    if ((jwt && !isTokenExpired(jwt)) || refresh_token_cookie) {
      adminsRefetch();
      schoolRefetch();

      const user_to_login = JSON.parse(
        localStorage.getItem("user_to_login") || "{}"
      );
      if (user_to_login.user) {
        localStorage.setItem("user_to_login", "");
        localStorage.setItem("user", JSON.stringify(user_to_login.user));
        if (user_to_login.admin) {
          localStorage.setItem("admin", JSON.stringify(user_to_login.admin));
        }
        localStorage.setItem(
          "preferences",
          JSON.stringify(user_to_login.user.preferences)
        );
      }
      if (localStorage.getItem("admin")) {
        const admin = JSON.parse(localStorage.getItem("admin") || "{}");
        if (
          admin.account_type !== "super_school" &&
          admin.account_type !== "school" &&
          admin.account_type !== "district" &&
          admin.account_type !== "super_district"
        ) {
          Cookies.remove("refresh_token_javascript");
          queryClient.invalidateQueries();
          window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
        }
      } else {
        Cookies.remove("refresh_token_javascript");
        queryClient.invalidateQueries();
        window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
      }
      setLoggedIn(true);
    } else {
      Cookies.remove("refresh_token_javascript");
      if (
        !isRequestFromReviewapp(window.location.origin) &&
        !isRequestFromLocal(window.location.origin) &&
        !isRequestFromDev(window.location.origin)
      ) {
        window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
      }
    }
  }, []);

  return (
    <div className="min-h-full">
      <AdminFeatureContext.Provider
        value={{ useAdminStyles, setUseAdminStyles }}
      >
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col border-r bg-white pb-4 pt-5">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Link key="topnavlogo" to={`${REACT_APP_ADMIN_LINK}`}>
                      <img
                        className="block h-6 w-auto"
                        src={deltamathLogo}
                        alt="DeltaMath Home"
                      />
                    </Link>
                    <span className="ml-2 mt-2 text-dm-brand-blue-500">
                      Admin
                    </span>
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    {loggedIn && schools && (
                      <SideNavigation
                        schools={schools}
                        hasIntegralAtAll={hasIntegralAtAll}
                        demoModeEnabled={demoModeEnabled}
                        setDemoModeEnabled={setDemoModeEnabled}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {districtIsSuccess && schoolIsSuccess && adminsData && (
          <IntegralTrialBanner
            districtData={districtData}
            schoolData={schoolData}
            adminData={adminsData}
          />
        )}

        {featureRenewalBanner &&
          loggedIn &&
          ((schoolIsSuccess && schoolData) ||
            (districtIsSuccess && districtData)) && (
            <RenewalBanner
              districtData={districtData}
              schoolData={schoolData}
              districtDataRefetch={districtDataRefetch}
              schoolDataRefetch={schoolRefetch}
              isDistrictDataFetched={isDistrictDataFetched}
              isSchoolDataFetched={isSchoolDataFetched}
            />
          )}

        <div className="z-10 hidden border-r px-6 lg:fixed lg:inset-y-0 lg:flex lg:flex-col lg:bg-white lg:pb-4 lg:pt-10">
          <div className="flex flex-shrink-0 items-center">
            <Menu as="div" className="relative flex-shrink-0">
              <Menu.Button>
                <span className="sr-only">Open user menu</span>

                <div className="flex flex-shrink-0 items-center">
                  <Link key="topnavlogo" to={`${REACT_APP_ADMIN_LINK}`}>
                    <img
                      className="block h-6 w-auto"
                      src={deltamathLogo}
                      alt="DeltaMath Home"
                    />
                  </Link>
                  <span className="ml-2 mt-2 text-dm-brand-blue-500">
                    Admin
                  </span>
                </div>
              </Menu.Button>
            </Menu>
          </div>
          <div className="flex h-0 flex-1 flex-col overflow-y-auto">
            {loggedIn && schools && (
              <SideNavigation
                schools={schools}
                hasIntegralAtAll={hasIntegralAtAll}
                demoModeEnabled={demoModeEnabled}
                setDemoModeEnabled={setDemoModeEnabled}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col lg:pl-[324px]">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-dm-blue bg-dm-blue lg:hidden">
            <button
              type="button"
              className="border-r border-dm-blue px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <Link to={`${REACT_APP_ADMIN_LINK}`} className="w-full">
              <span className="block h-16 w-full pt-4 text-center text-xl text-white">
                DeltaMath Admin Portal
              </span>
            </Link>
          </div>
          <main className="flex-1">
            {userContext.state?.decodedJwt?.data?.decampTarget ===
              "manager" && <ImpersonationHeader />}
            {loggedIn && (
              <div className="bg-dm-background-blue-100">
                <Routes>
                  <Route
                    path=""
                    element={
                      <ErrorBoundary>
                        <DistrictIndex />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="district/:selected_tab"
                    element={
                      <ErrorBoundary>
                        <DistrictAdministrators demoMode={demoModeEnabled} />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="district/add-school"
                    element={
                      <ErrorBoundary>
                        <AddSchool schoolRefetch={schoolRefetch} />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="schools/:school_id"
                    element={
                      <ErrorBoundary>
                        <School />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="schools/:school_id/:selected_tab"
                    element={
                      <ErrorBoundary>
                        <School />
                      </ErrorBoundary>
                    }
                  />

                  <Route
                    path="schools"
                    element={
                      <ErrorBoundary>
                        <SchoolsIndex />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="teacher/:teachercode"
                    element={
                      <ErrorBoundary>
                        {schools && <Teacher schools={schools} />}
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <ErrorBoundary>
                        <Profile hasIntegralAtAll={hasIntegralAtAll} />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="teacher/:teachercode/assignment/:assignment_id"
                    element={
                      <ErrorBoundary>
                        <Assignment hasIntegralAtAll={hasIntegralAtAll} />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="teacher/:teachercode/assignment/:assignment_id/:selected_tab"
                    element={
                      <ErrorBoundary>
                        <Assignment hasIntegralAtAll={hasIntegralAtAll} />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="assignment/:assignment_id"
                    element={
                      <ErrorBoundary>
                        <Assignment hasIntegralAtAll={hasIntegralAtAll} />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="push-assignment/:assignment_id"
                    element={
                      <ErrorBoundary>
                        {schools && (
                          <PushAssignment
                            key={1}
                            schoolsWithIntegral={schoolsWithIntegral}
                            schools={schools}
                          />
                        )}
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="push-assignment"
                    element={
                      <ErrorBoundary>
                        {schools && (
                          <PushAssignment
                            key={2}
                            schoolsWithIntegral={schoolsWithIntegral}
                            schools={schools}
                          />
                        )}
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="view-pushed-assignments"
                    element={
                      <ErrorBoundary>
                        <ViewPushedAssignments />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="help-videos"
                    element={
                      <ErrorBoundary>
                        <HelpVideos />
                      </ErrorBoundary>
                    }
                  />
                  {/* <Route
                  path="view-pushed-assignments/:assignment_id"
                  element={
                    <ErrorBoundary>
                      {schools && (
                        <Edit
                          schoolsWithIntegral={schoolsWithIntegral}
                          schools={schools}
                        />
                      )}
                    </ErrorBoundary>
                  }
                /> */}
                  {/* <Route
                  path="testing/:assignment_id"
                  element={
                    <ErrorBoundary>
                      {schools && (
                        <PushedAssignment
                          schoolsWithIntegral={schoolsWithIntegral}
                          schools={schools}
                        />
                      )}
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="testing"
                  element={
                    <ErrorBoundary>
                      {schools && (
                        <PushedAssignment
                          schoolsWithIntegral={schoolsWithIntegral}
                          schools={schools}
                        />
                      )}
                    </ErrorBoundary>
                  }
                /> */}
                  <Route
                    path="reports/student-usage"
                    element={
                      <ErrorBoundary>
                        <StudentUsageContainer
                          schoolsWithIntegral={schoolsWithIntegral}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="reports/student-usage/select-scope"
                    element={
                      <ErrorBoundary>
                        <StudentUsageContainer
                          schoolsWithIntegral={schoolsWithIntegral}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="reports/student-performance"
                    element={
                      <ErrorBoundary>
                        <StudentPerformanceContainer
                          schoolsWithIntegral={schoolsWithIntegral}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="reports/student-performance/:page"
                    element={
                      <ErrorBoundary>
                        <StudentPerformanceContainer
                          schoolsWithIntegral={schoolsWithIntegral}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="reports/student-performance/:page/:tab"
                    element={
                      <ErrorBoundary>
                        <StudentPerformanceContainer
                          schoolsWithIntegral={schoolsWithIntegral}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="reports/student-usage/view-report"
                    element={
                      <ErrorBoundary>
                        <StudentUsageContainer
                          schoolsWithIntegral={schoolsWithIntegral}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="reports/student-usage/select-scope/:page"
                    element={
                      <ErrorBoundary>
                        <StudentUsageContainer
                          schoolsWithIntegral={schoolsWithIntegral}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="reports/student-usage/view-report/:report_type"
                    element={
                      <ErrorBoundary>
                        <StudentUsageContainer
                          schoolsWithIntegral={schoolsWithIntegral}
                        />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="login"
                    element={
                      <ErrorBoundary>
                        <Login setLoggedIn={setLoggedIn} accountType="admin" />
                      </ErrorBoundary>
                    }
                  />
                </Routes>
              </div>
            )}
            {!loggedIn && (
              <Login setLoggedIn={setLoggedIn} accountType="admin" />
            )}
          </main>
        </div>
      </AdminFeatureContext.Provider>
    </div>
  );
}
