import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import PasswordBox from "../BetaSignUp/PasswordBox";
import Button from "../../../student/components/generic/button";
import { useLogin } from "../../../utils/auth/login";
import { GoogleLoginButton } from "../../../manager/components/GoogleLoginButton";
import { useHomepageSignupContext } from "../Home/HomepageSignupContext";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { processLoginResponse } from "../../../utils/auth/processLoginResponse";
import { useUserContext } from "../../../shared/contexts/UserContext";

type Props = {
  setLoggedIn?: (loggedIn: boolean) => void;
  updatePageState: (state: "forgot") => void;
  dontHaveAnAccount?: boolean;
};

const LoginForm = (props: Props) => {
  const userContext = useUserContext();
  const queryClient = useQueryClient();
  const login = useLogin();
  const { setShowSignup } = useHomepageSignupContext();

  const [loginLoading, setLoginLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    setLoginLoading(true);
    setError(undefined);
    (async () => {
      try {
        const { success } = await login(email, password, "learner");
        if (props.setLoggedIn) {
          props.setLoggedIn(success);
        }
        setLoginLoading(false);
        if (success) {
          // if donthaveaccount is true then we are on the landing page so we redirect to the dashboard
          if (props.dontHaveAnAccount) {
            navigate(`${REACT_APP_LEARNER_LINK}/dashboard${`?login=true`}`);
          } else {
            navigate(`${window.location.search}${`?login=true`}`);
          }
        } else {
          setError(
            "Unable to login with that username and password. Please try again"
          );
        }
      } catch (e: any) {
        if (props.setLoggedIn) {
          props.setLoggedIn(false);
        }
        setLoginLoading(false);
        setError(e.message);
      }
    })();
  };

  const handleGoogleLogin = useCallback(
    (data) => {
      if (data.found === false) {
        setError("Unable to log in with that email address");
      } else {
        const { success } = processLoginResponse(data, userContext, false);
        if (!success) {
          setError("There was a problem logging in");
        } else {
          navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
        }
      }
    },
    [navigate, userContext]
  );

  return (
    <>
      <h2 className="mt-6 text-center font-[Lora] text-2xl font-bold text-dm-brand-blue-600">
        Log In
      </h2>
      <div className="mt-2 flex-grow sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 sm:rounded-lg sm:px-10">
          <GoogleLoginButton
            onLogin={handleGoogleLogin}
            accountType="learner"
          />
          <div className="mb-4 mt-6 text-center">
            <span className="text-sm font-bold leading-[26px]"></span>
            Or
          </div>
          <form className="space-y-6" onSubmit={submitForm}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-bold text-dm-charcoal-800"
              >
                Email or Username
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type={"text"}
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <PasswordBox
                label="Password"
                value={password}
                error={undefined}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="!mt-2">
              <button
                type="button"
                className="cursor-pointer text-sm text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70"
                disabled={loginLoading}
                onClick={() => props.updatePageState("forgot")}
              >
                Forgot Password?
              </button>
            </div>

            <Button submit disabled={loginLoading} className="w-full">
              Log In
            </Button>
            {loginLoading && <div>Logging you in...</div>}
            {error && <div className="text-dm-error-500">{error}</div>}

            {props.dontHaveAnAccount && (
              <div>
                <p className="mt-2 text-center text-sm font-bold">
                  Don&apos;t have an account?
                  <button
                    type="button"
                    className="cursor-pointer pl-1 text-sm text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70"
                    onClick={() => setShowSignup(true)}
                  >
                    Sign Up
                  </button>
                </p>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
