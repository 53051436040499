import clsx from "clsx";
import { HTMLAttributes, useState } from "react";

export const CopyToClipboardButton: React.FC<
  { value: string } & HTMLAttributes<HTMLButtonElement>
> = ({ value, className, ...props }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(value);
    } catch (e) {
      // Ignore. Probably failed the write permission check; requires https in
      // some browsers
      console.error(e);
      return;
    }
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <button
      {...props}
      className={clsx(
        "text-dm-charcoal-200 hover:text-dm-charcoal-500",
        className
      )}
      onClick={copyToClipboard}
      title="Copy to clipboard"
    >
      {copied ? (
        <i className="far fa-check !text-dm-success-500" />
      ) : (
        <i className="far fa-clipboard" />
      )}
    </button>
  );
};
