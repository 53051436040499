export const RemoveItemButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <button
    className="group flex h-10 w-10 items-center justify-center rounded border border-transparent p-0 text-xl hover:border-dm-charcoal-200"
    type="button"
    onClick={onClick}
    title="Remove this item"
  >
    <i className="far fa-times !text-dm-charcoal-200 group-hover:!text-black" />
  </button>
);
