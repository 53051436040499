import { useLearnerContext } from "../contexts/LearnerContext";

/** Check if the current logged-in learner has access to a feature */
export function useLearnerFeature(featureName: string) {
  const { state } = useLearnerContext();
  if (!state) {
    throw new Error(
      "useLearnerFeature must be used within a LearnerContextProvider"
    );
  }
  return state.learner?.features?.includes(featureName);
}
