import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import { useCourseContext } from "../../contexts/CourseContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import Sidebar from "../Sidebar/subunit";
import PreQuiz from "./PreQuiz";
import Practice from "./Practice";
import PostQuiz from "./PostQuiz";
import ScoreBar from "../ScoreBar";
import {
  Course,
  Subunit,
  Unit,
  LearnerAssignment,
  LearnerAssignmentTypes,
  LearnerAssignmentSkillData,
} from "../../types";
import { SubunitLandingLoading } from "./SubunitLandingLoading";
import { LandingPageError } from "../LandingPageError";
import MobileQuestionDropdown from "../Sidebar/MobileQuestionDropdown";
import { getReadableAssignmentType } from "../../utils";
import ScoreBarPracticeMobile from "../ScoreBarPracticeMobile";
import { LearnerPage } from "../Layouts/LearnerPage";
import { useTestTimer } from "../../utils/useTestTimer";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import runConfetti from "../../utils/confetti";
import { compact } from "lodash";
import { AssignmentSubmissionLoading } from "../AssignmentSubmissionLoading";

const SectionUrl: Record<string, LearnerAssignmentTypes> = {
  prequiz: "preQuiz",
  practice: "practice",
  postquiz: "postQuiz",
  unittest: "unitTest",
  coursetest: "courseTest",
};

const SubunitLanding = () => {
  const { coursePath, unitPath, subunitPath } = useParams();

  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();

  const courseData = courseContext.getCourseData(coursePath);
  const unitData = courseContext.getUnitData(unitPath, coursePath);
  const subunitData = courseContext.getSubunitData(
    subunitPath,
    unitPath,
    coursePath
  );

  useEffect(() => {
    courseContext.setActiveCourse(courseData?.id);
  }, [courseContext, courseData]);

  if (
    courseContext.state.isLoadingCourse ||
    learnerContext.state.isLoadingProgress ||
    courseContext.state.isLoadingSubunits
  ) {
    return (
      <LearnerPage
        title={undefined}
        sidebar={<Sidebar subunitProgress={undefined} isLoadingProgress />}
        noPadding
        noPaymentErrorBanner
      >
        <SubunitLandingLoading />
      </LearnerPage>
    );
  }

  if (!courseData || !unitData || !subunitData) {
    return (
      <LearnerPage
        title={subunitData?.name ?? unitData?.name ?? courseData?.name}
        sidebar={<Sidebar subunitProgress={undefined} />}
        noPaymentErrorBanner
      >
        <LandingPageError>Unable to load this page</LandingPageError>
      </LearnerPage>
    );
  }

  return (
    <SubunitLandingWithData
      courseData={courseData}
      unitData={unitData}
      subunitData={subunitData}
    />
  );
};

const SubunitLandingWithData: React.FC<{
  courseData: Course;
  subunitData: Subunit;
  unitData: Unit;
}> = ({ courseData, subunitData, unitData }) => {
  const navigate = useNavigate();
  const learnerContext = useLearnerContext();
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");
  const { coursePath, unitPath, subunitPath, assignmentType, indexOfSkill } =
    useParams();

  const courseProgress = learnerContext.getProgress(courseData.id);
  const unitProgress =
    courseProgress &&
    courseProgress.units.find((u) => u.unitId === unitData.id);
  const subunitProgress =
    unitProgress &&
    unitProgress.subunits.find((s) => s.subunitId === subunitData.id);

  const assignmentTypeFormatted: LearnerAssignmentTypes =
    (assignmentType && SectionUrl[assignmentType.toLowerCase()]) ||
    (subunitProgress?.practice?.progress === 1
      ? "postQuiz"
      : subunitProgress?.skippedPreQuiz || subunitProgress?.preQuiz?.submitted
      ? "practice"
      : "preQuiz");
  const questionIndex = isNaN(Number(indexOfSkill)) ? 0 : Number(indexOfSkill);
  const queryString = window.location.search;

  const [isWaitingForAssignmentSubmit, setIsWaitingForAssignmentSubmit] =
    useState(false);

  type urlParamType = "refetch" | "complete" | undefined;
  const urlParam = useCallback((param: urlParamType): string => {
    if (param === "refetch") {
      return "/0?refetch=true";
    } else if (param === "complete") {
      return "?complete=true";
    }

    return "";
  }, []);

  const submitCallback = (param?: urlParamType) => {
    navigate(
      `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/${assignmentType}${urlParam(
        param
      )}`
    );
  };

  useEffect(() => {
    if (!assignmentType && subunitData?.id) {
      navigate(
        `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/${assignmentTypeFormatted.toLowerCase()}`
      );
    }
  }, [
    assignmentType,
    assignmentTypeFormatted,
    coursePath,
    navigate,
    subunitData,
    subunitPath,
    unitPath,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("complete") === "true") {
      runConfetti(learnerContext.learner);
      const params = new URLSearchParams(urlParams.toString());
      params.delete("complete");
      navigate(
        `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/${assignmentTypeFormatted.toLowerCase()}${
          params.toString() !== "" ? `?${params.toString()}` : ""
        }`,
        { replace: true }
      );
    }
  }, [
    assignmentTypeFormatted,
    coursePath,
    navigate,
    queryString,
    subunitPath,
    unitPath,
    urlParam,
  ]);

  const unitOrderPosition =
    (courseData &&
      unitData &&
      courseData?.unitOrder?.indexOf(unitData.id) + 1) ||
    undefined;
  const subunitOrderPosition =
    (unitData &&
      subunitData &&
      unitData?.subunitOrder?.indexOf(subunitData.id) + 1) ||
    undefined;

  useEffect(() => {
    if (
      (isWaitingForAssignmentSubmit &&
        assignmentType === "prequiz" &&
        subunitProgress?.preQuiz?.assignment?.submitted) ||
      (isWaitingForAssignmentSubmit &&
        assignmentType === "postquiz" &&
        subunitProgress?.postQuiz?.assignment?.submitted)
    ) {
      setIsWaitingForAssignmentSubmit(false);
    }
  }, [
    assignmentType,
    isWaitingForAssignmentSubmit,
    subunitProgress?.postQuiz?.assignment?.submitted,
    subunitProgress?.preQuiz?.assignment?.submitted,
  ]);

  const subunitDifficulty =
    subunitProgress?.difficulty || learnerContext.getLearnerLevel();

  const skillsByLevel =
    subunitData.skills?.filter((skill) =>
      [subunitDifficulty, "both"].includes(skill.difficulty)
    ) || [];

  const scoreBarTitle = compact([
    unitOrderPosition ? `Unit ${unitOrderPosition}:` : undefined,
    subunitOrderPosition ? `Section ${subunitOrderPosition}:` : undefined,
    subunitData?.name,
  ]).join(" ");

  const landingPageCopy = `${
    unitOrderPosition && "Unit " + unitOrderPosition + ", "
  }${subunitOrderPosition && "Section " + subunitOrderPosition + " of the "}${
    courseData?.name
  }`;

  const preQuiz100 = subunitProgress?.preQuiz?.assignment?.grade === 1;

  const assignment: LearnerAssignment | undefined =
    (assignmentTypeFormatted !== "unitTest" &&
      assignmentTypeFormatted !== "courseTest" &&
      subunitProgress?.[assignmentTypeFormatted]?.assignment) ||
    undefined;
  const currentSkill: LearnerAssignmentSkillData | undefined =
    assignment?.skills?.[questionIndex];

  const testTimer = useTestTimer(assignment);

  const estimatedTime = Math.max(
    5,
    Math.ceil((subunitData?.avgQuizTime || 0) / 60 / 5) * 5
  );

  const assignmentTypeStr =
    assignmentType === "prequiz"
      ? "Pre-Quiz"
      : assignmentType === "practice"
      ? "Practice"
      : assignmentType === "postquiz"
      ? "Post-Quiz"
      : undefined;

  return (
    <LearnerPage
      title={compact([subunitData.name, assignmentTypeStr]).join(" ")}
      sidebar={<Sidebar subunitProgress={subunitProgress} />}
      noPadding
      noPaymentErrorBanner
    >
      <div className="flex min-h-full flex-grow flex-col">
        <ScoreBar
          pageState={assignmentTypeFormatted}
          assignment={assignment}
          title={scoreBarTitle}
          preQuizAssignment={
            assignmentTypeFormatted === "practice"
              ? subunitProgress?.preQuiz?.assignment
              : undefined
          }
          postQuizAssignment={
            assignmentTypeFormatted === "practice"
              ? subunitProgress?.postQuiz?.assignment
              : undefined
          }
          skippedPreQuiz={!!subunitProgress?.skippedPreQuiz}
          preQuiz100={preQuiz100}
          callback={submitCallback}
          finishedSubunit={subunitProgress?.progress === 1}
          testTimer={testTimer}
          onSubmitAssignment={() => {
            setIsWaitingForAssignmentSubmit(true);
          }}
        />
        <h2
          className={clsx(
            "bg-white px-4 py-2 text-sm font-bold lg:hidden",
            indexOfSkill === undefined && "border-b border-dm-charcoal-100"
          )}
        >{`${scoreBarTitle} ${getReadableAssignmentType(
          assignmentTypeFormatted
        )}`}</h2>
        {isSmallDevice && indexOfSkill !== undefined && (
          <MobileQuestionDropdown type={assignmentTypeFormatted} />
        )}
        {isSmallDevice &&
          indexOfSkill !== undefined &&
          assignmentType === "practice" &&
          currentSkill !== undefined && (
            <ScoreBarPracticeMobile
              currentSkill={currentSkill}
              preQuizAssignment={
                assignmentTypeFormatted === "practice"
                  ? subunitProgress?.preQuiz?.assignment
                  : undefined
              }
              postQuizAssignment={
                assignmentTypeFormatted === "practice"
                  ? subunitProgress?.postQuiz?.assignment
                  : undefined
              }
            />
          )}

        <div className="flex flex-grow flex-col bg-dm-background-blue-100 pb-8 text-center text-dm-charcoal-500 sm:p-6 lg:p-8">
          {assignmentType === "prequiz" &&
            (!subunitProgress?.preQuiz ||
              (subunitProgress.preQuiz.ref &&
                subunitProgress.preQuiz.assignment)) &&
            (isWaitingForAssignmentSubmit ? (
              <AssignmentSubmissionLoading assignmentType="preQuiz" />
            ) : (
              <PreQuiz
                skills={skillsByLevel}
                courseId={courseData.id}
                coursePath={coursePath}
                unitId={unitData.id}
                unitPath={unitPath}
                subunitId={subunitData.id}
                subunitPath={subunitPath}
                subunitProgress={subunitProgress}
                subunitCopy={landingPageCopy}
                testTimer={testTimer}
                estimatedTime={estimatedTime}
              />
            ))}
          {assignmentType === "practice" &&
            (!subunitProgress?.practice ||
              (subunitProgress.practice.ref &&
                subunitProgress.practice.assignment)) && (
              <Practice
                skills={skillsByLevel}
                courseId={courseData.id}
                coursePath={coursePath}
                unitId={unitData.id}
                unitPath={unitPath}
                subunitId={subunitData.id}
                subunitPath={subunitPath}
                subunitProgress={subunitProgress}
              />
            )}
          {assignmentType === "postquiz" &&
            (!subunitProgress?.postQuiz ||
              (subunitProgress.postQuiz.ref &&
                subunitProgress.postQuiz.assignment)) &&
            (isWaitingForAssignmentSubmit ? (
              <AssignmentSubmissionLoading assignmentType="postQuiz" />
            ) : (
              <PostQuiz
                skills={skillsByLevel}
                courseId={courseData.id}
                coursePath={coursePath}
                unitId={unitData.id}
                unitPath={unitPath}
                subunitId={subunitData.id}
                subunitPath={subunitPath}
                subunitProgress={subunitProgress}
                testTimer={testTimer}
                estimatedTime={estimatedTime}
              />
            ))}
        </div>
      </div>
    </LearnerPage>
  );
};

export default SubunitLanding;
