import FlagIcon from "../../../shared/icons/FlagIcon";
import Button from "../../../student/components/generic/button";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { SkeletonText } from "../Skeleton";

export const DashboardHeader: React.FC<{
  headerText: string;
  loaded?: boolean;
  challengeMode?: boolean;
}> = ({ headerText, loaded = true, challengeMode = false }) => {
  return (
    <header className="relative mb-6 flex w-full justify-between">
      <h1 className="font-serif text-lg font-bold">
        <SkeletonText loaded={loaded}>{headerText}</SkeletonText>
      </h1>

      {challengeMode && (
        <Button
          size="small"
          type="outline"
          className="svg-hover flex h-fit w-min items-center gap-1 text-nowrap rounded px-8 py-1 font-bold leading-6"
          href={`${REACT_APP_LEARNER_LINK}/parent/profile`}
        >
          <FlagIcon />
          Challenge Mode On
        </Button>
      )}
    </header>
  );
};
