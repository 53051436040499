export const TableHead: React.FC<{ cols: string[] }> = ({ cols }) => (
  <thead>
    <tr>
      {cols.map((c) => (
        <th key={c} className="pb-2 pr-2 text-left">
          {c}
        </th>
      ))}
    </tr>
  </thead>
);

export const TableCell: React.FC = ({ children }) => (
  <td className="pb-2 pr-2 align-top">{children}</td>
);
