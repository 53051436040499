import Button from "../../../student/components/generic/button";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { deltamathAPI } from "../../utils";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { EXPERIMENTS_LIST } from "./queries";

interface FormInput {
  experimentName: string;
}

export const CreateExperimentForm: React.FC = () => {
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const { register, handleSubmit, reset } = useForm<FormInput>();

  const { mutateAsync: createExperimentMutation, isLoading } = useMutation(
    (formData: FormInput) => {
      return axios.post(
        `${deltamathAPI()}/experiments/create`,
        JSON.stringify(formData),
        withJsonHeader()
      );
    }
  );

  const onSubmit: SubmitHandler<FormInput> = async (formData) => {
    try {
      await createExperimentMutation(formData);
      reset();
      queryClient.invalidateQueries(EXPERIMENTS_LIST);
    } catch (e) {
      toastContext.addToast({
        message: "There was an error creating the experiment",
        status: "Error",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex items-center gap-3">
      <input
        type="text"
        id="flag-name"
        className="w-96 rounded border border-dm-charcoal-200 px-3 py-1"
        placeholder="Experiment name"
        {...register("experimentName")}
      />
      <Button size="small" isLoading={isLoading}>
        Create
      </Button>
    </form>
  );
};
