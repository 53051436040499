import { useDocumentTitle } from "usehooks-ts";
import { REACT_APP_MANAGER_LINK } from "../../../utils";
import { NavLink, useParams } from "react-router-dom";
import { BasicContentContainer } from "../BasicContentContainer";
import { DmLoadingSpinner } from "../../utils/functions";
import { ChangeStateForm } from "./ChangeStateForm";
import { EditExperimentForm } from "./EditExperimentForm";
import { useExperimentItemQuery } from "./queries";
import { CopyToClipboardButton } from "./CopyToClipboardButton";

export const ExperimentItemPage: React.FC = () => {
  useDocumentTitle("Experiments");
  const { experimentId } = useParams();

  const {
    data: experimentData,
    isLoading,
    isError,
  } = useExperimentItemQuery(String(experimentId));

  return (
    <div className="flex flex-col gap-10 p-6">
      <NavLink
        className="flex items-center gap-2 text-dm-charcoal-500 hover:text-black"
        to={`${REACT_APP_MANAGER_LINK}/engineering/experiments`}
      >
        <i className="far fa-arrow-left" />
        <span>Back to all experiments</span>
      </NavLink>
      <div>
        <h1 className="mb-1 text-2xl font-bold">
          {experimentData ? (
            <span className="flex items-center gap-2">
              <span>
                Edit <code className="">{experimentData.experiment.name}</code>
              </span>
              <CopyToClipboardButton
                className="text-lg"
                value={experimentData.experiment.name}
              />
            </span>
          ) : (
            "Edit experiment"
          )}
        </h1>
        <p>Change the experiment state or edit variants and overrides.</p>
      </div>
      {isError ? (
        <BasicContentContainer>
          <p className="text-dm-error-500">
            There was a problem fetching experiment data
          </p>
        </BasicContentContainer>
      ) : isLoading ? (
        <BasicContentContainer>
          <DmLoadingSpinner message="" />
        </BasicContentContainer>
      ) : experimentData ? (
        <>
          <ChangeStateForm experiment={experimentData.experiment} />
          <EditExperimentForm experiment={experimentData.experiment} />
        </>
      ) : null}
    </div>
  );
};
