import { NavLink } from "react-router-dom";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { BasicContentContainer } from "../BasicContentContainer";
import { Experiment } from "./types";
import { REACT_APP_MANAGER_LINK } from "../../../utils";
import { ExperimentState } from "./ExperimentState";
import { formatRelative } from "date-fns";
import { capitalize } from "lodash";
import { CopyToClipboardButton } from "./CopyToClipboardButton";

export const ExperimentsTable: React.FC<{ experiments: Experiment[] }> = ({
  experiments,
}) => {
  if (experiments.length === 0) {
    return (
      <BasicContentContainer>
        <p>
          There are no experiments at the moment. Use the form above to create
          one.
        </p>
      </BasicContentContainer>
    );
  }

  return (
    <DeltaMathTable
      data={experiments}
      columns={[
        {
          Header: "Name",
          ...{ align: "left" },
          Cell: (props: any) => {
            const { name, _id } = props.row.original;
            return (
              <span className="flex items-center gap-1">
                <NavLink
                  className={"text-blue-500 underline-offset-2 hover:underline"}
                  to={`${REACT_APP_MANAGER_LINK}/engineering/experiments/${_id}`}
                >
                  {name}
                </NavLink>
                <CopyToClipboardButton className="px-1 text-sm" value={name} />
              </span>
            );
          },
        },
        {
          Header: "State",
          ...{ align: "left" },
          Cell: (props: any) => (
            <ExperimentState state={props.row.original.state} />
          ),
        },
        {
          Header: "Variants",
          ...{ align: "left" },
          Cell: (props: any) => {
            const { variants, state, winningVariant } = props.row
              .original as Experiment;
            if (state === "closed" || state === "retired") {
              return (
                <span
                  className="flex items-center gap-2"
                  title={`Winning variant: ${winningVariant}`}
                >
                  <i className="fas fa-award" /> <code>{winningVariant}</code>
                </span>
              );
            }

            return variants.map((variant: any) => {
              return (
                <div key={variant.name} className="flex items-center gap-2">
                  <code>{variant.name}</code>
                  <span>{variant.weight * 100}%</span>
                </div>
              );
            });
          },
        },
        {
          Header: "Overrides",
          ...{ align: "left" },
          Cell: (props: any) => {
            const { overrides } = props.row.original;
            return overrides.length === 0
              ? "None"
              : overrides.length === 1
              ? "1 override"
              : `${overrides.length} overrides`;
          },
        },
        {
          Header: "Last updated",
          ...{ align: "left" },
          Cell: (props: any) =>
            capitalize(
              formatRelative(new Date(props.row.original.updatedAt), new Date())
            ),
        },
      ]}
    />
  );
};
