import { useState } from "react";
import {
  generateTimedProblems,
  TimedProblemType,
} from "./timed_problem_generator";
import { TimedProblem } from "../../TimedModule";
import axios from "axios";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../../manager/utils";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import Modal from "../../generic/Modal";
import { replaceModuleCodeSlashes } from "../utils";

type TimedProblemData = {
  html: Record<any, any>;
  timed: boolean;
  programmer_name: string;
  skillcode: string;
  timedCode: string;
};

export const TestingTimedProblem: React.FC<{
  problem: TimedProblemType;
  problemData: TimedProblemData;
}> = ({ problem, problemData }) => {
  const toastContext = useDeltaToastContext();
  const [showModal, setShowModal] = useState(false);
  const [answer, setAnswer] = useState<number | undefined>(undefined);
  const [correctAnswer, setCorrectAnswer] = useState<number | undefined>(
    undefined
  );
  const [solutionText, setSolutionText] = useState<string[] | undefined>(
    undefined
  );
  const [showStart, setShowStart] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const [timedProblem, setTimedProblem] = useState<TimedProblemType>(problem);

  /* Check answer handler */
  const handleCheckAnswer = (index: number) => {
    setAnswer(index); // set the student answer
    setCorrectAnswer(problem.theAnswer); // sets the correct answer

    // if the problem is incorrect, the solution text should show
    if (index !== problem.theAnswer) {
      setSolutionText(problem.explanation);
    } else {
      // if there is a solution graph, then hide it if the student was correct
      setTimedProblem({
        ...problem,
        showImage2: false,
      });
    }

    setShowStart(true);
    setScore(score + 1);
  };

  const uploadModule = useMutation(
    (body: { _id: string; code: string }) => {
      return axios.post(
        `${deltamathAPI()}/programmer/timed/upload`,
        JSON.stringify(body),
        withJsonHeader()
      );
    },
    {
      onSuccess: () => {
        const message = "Module successfully uploaded!";
        toastContext.addToast({
          message,
          status: "Success",
        });
      },
      onError: () => {
        const message =
          "An error occurred while uploading the module. Please try again.";
        toastContext.addToast({
          message,
          status: "Error",
        });
      },
    }
  );

  const onClose = () => setShowModal(false);

  const handleUpload = () => {
    // runs the generate timed problems function - attempts to catch any infinite loops before the module is uploaded
    generateTimedProblems(problemData.skillcode, problemData.timedCode, 100);

    const { programmer_name, skillcode, timedCode } = problemData;
    const _id = `${programmer_name}/${skillcode.substring(
      0,
      skillcode.lastIndexOf(".")
    )}`;
    const code = replaceModuleCodeSlashes(timedCode);
    uploadModule.mutate({
      _id,
      code,
    });
    onClose();
  };

  return (
    <>
      <div className="relative mt-10 rounded-lg border border-dm-charcoal-100 bg-white px-2 py-9 sm:px-5 md:px-9">
        <TimedProblem
          problem={timedProblem}
          handleCheckAnswer={handleCheckAnswer}
          answer={answer}
          correctAnswer={correctAnswer}
          solutionText={solutionText}
          btnsDisabled={showStart}
          isComplete={false}
          score={score}
          required={1}
        />
      </div>
      <div className="mt-6">
        <button
          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-400"
          onClick={() => setShowModal(true)}
        >
          Upload Module
        </button>
        <Modal
          visible={showModal}
          onClose={onClose}
          title="Confirm Timed Module Upload"
          body={"Are you sure you want to upload this timed module?"}
          noLine
          confirmButtonText="Yes"
          onConfirm={handleUpload}
          secondaryButtonText="Cancel"
          secondaryOnClick={onClose}
          renderMath
        />
      </div>
    </>
  );
};
