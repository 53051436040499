import { useEffect, useRef } from "react";
import { deltamathAPI } from "../utils";
import { GoogleLoginResponse } from "../../utils/auth/types";
import { useScript } from "usehooks-ts";
import { LoginAccountType } from "../../shared/types";

export const GoogleLoginButton: React.FC<{
  onLogin: (loginData: GoogleLoginResponse) => void;
  accountType?: LoginAccountType;
}> = ({ onLogin, accountType }) => {
  const scriptStatus = useScript("https://accounts.google.com/gsi/client");
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scriptStatus === "ready") {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        callback: async (response: { credential: string }) => {
          const loginRequest = await fetch(
            `${deltamathAPI()}/login_with_google`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                googleToken: response.credential,
                account_type: accountType,
              }),
            }
          );
          onLogin(await loginRequest.json());
        },
      });

      window.google.accounts.id.renderButton(containerRef.current, {
        theme: "outline",
        size: "large",
        // The container should have a width when this happens. Worst
        // case is it will render a small sign-in button if width is 0
        width: containerRef.current?.clientWidth ?? 0,
      });
    }
  }, [accountType, onLogin, scriptStatus]);

  return <div ref={containerRef} />;
};
