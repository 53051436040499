import React from "react";
import Modal from "../../../student/components/generic/Modal";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";
import Button from "../../../student/components/generic/button";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";

type Props = {
  visible: boolean;
  onClose: () => void;
};

const ChallengeModeChangedModal = (props: Props) => {
  const learnerContext = useLearnerContext();
  const toastContext = useDeltaToastContext();

  // failsafe to be able to dismiss modal if something goes super wrong
  const [dismissed, setDismissed] = React.useState(false);

  const { mutate } = useMutation(
    () => {
      return axios.post(
        `${deltamathAPI()}/learner/shared/challenge_modal_seen`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: () => {
        learnerContext.refetchLearner();
        setDismissed(true);
      },
      onError: () => {
        toastContext.addToast({
          message: "Issue dismissing challenge mode modal",
          status: "Error",
        });
        setDismissed(true);
      },
    }
  );

  const body =
    learnerContext.learner?.level === "easy" ? (
      <>
        Challenge Mode is off, except for sections already started on Challenge
        Mode
      </>
    ) : (
      <>
        Your parent/guardian has turned on Challenge Mode. Most content will be
        the same, but <b>new sections</b> will now show fewer basic problems and
        more challenging problems. Let them know if a course becomes too
        difficult.
      </>
    );

  const title =
    learnerContext.learner?.level === "easy"
      ? "You're Off Challenge Mode Now!"
      : "You're On Challenge Mode";

  const filePath = `/images/learner/challenge/${
    learnerContext.learner?.level === "hard" ? "deltie-desk" : "deltie-reading"
  }`;

  return (
    <Modal
      visible={
        props.visible && learnerContext.learner !== undefined && !dismissed
      }
      onClose={() => {
        props.onClose();
      }}
      body={
        <div className="flex flex-col items-center gap-10">
          <ResponsiveImage
            className="mb-4 max-w-56 object-contain"
            srcs={[
              getFilePath(`${filePath}.png`),
              getFilePath(`${filePath}@2x.png`),
            ]}
            alt="Deltie sitting at a desk."
          />
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl font-bold leading-10">{title}</h2>
            <p className="leading-[26px]">{body}</p>
          </div>
        </div>
      }
      footer={
        <div className="mt-8 flex justify-center border-t py-8">
          <Button className="w-full md:w-3/4" onClick={() => mutate()}>
            Got It!
          </Button>
        </div>
      }
    />
  );
};

export default ChallengeModeChangedModal;
