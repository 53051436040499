import clsx from "clsx";
import { Switch } from "@headlessui/react";

/**
 * Toggle with a single label.
 * Feel free to edit to make this a more generic component!
 */
export const DMToggleDoubleLabel = ({
  checked,
  onChange,
  offLabel,
  onLabel,
  disabled = false,
}: {
  checked: boolean;
  onChange: (enabled: boolean) => void;
  offLabel: string;
  onLabel: string;
  disabled?: boolean;
}) => (
  <Switch.Group>
    <div
      className={clsx(
        "flex items-center",
        disabled && "cursor-not-allowed opacity-50"
      )}
    >
      <Switch.Label className={clsx(`mr-2`, disabled && "cursor-not-allowed")}>
        {offLabel}
      </Switch.Label>
      <Switch
        checked={checked}
        onChange={disabled ? undefined : onChange}
        className={clsx(
          checked ? "bg-dm-brand-blue-500" : "bg-dm-charcoal-200",
          `relative mt-1 inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-dm-brand-blue-500 focus:ring-offset-2`,
          disabled && "cursor-not-allowed"
        )}
      >
        <span
          className={clsx(
            checked ? "translate-x-6" : "translate-x-1",
            `inline-block h-4 w-4 transform rounded-full bg-white transition-transform`
          )}
        />
      </Switch>
      <Switch.Label className={clsx("ml-2", disabled && "cursor-not-allowed")}>
        {onLabel}
      </Switch.Label>
    </div>
  </Switch.Group>
);
