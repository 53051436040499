import { FieldValues, UseFormRegister } from "react-hook-form";

interface Props<T extends FieldValues> {
  name: Parameters<UseFormRegister<T>>[0];
  register: UseFormRegister<T>;
  validation: Parameters<UseFormRegister<T>>[1];
  type: "text" | "number";
  step?: number;
  disabled?: boolean;
}
export function TextInput<T extends FieldValues>({
  name,
  register,
  validation,
  ...attrs
}: Props<T>) {
  return (
    <input
      className="h-10 rounded px-3 disabled:bg-dm-charcoal-100 disabled:text-dm-charcoal-500"
      id={name}
      {...attrs}
      {...register(name, validation)}
    />
  );
}
