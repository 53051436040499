import { format } from "date-fns";
import { usePaymentUpdate } from "../../utils/usePaymentUpdate";
import Button from "../../../student/components/generic/button";
import { useLearnerContext } from "../../contexts/LearnerContext";
import clsx from "clsx";

export const PaymentErrorBanner: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { learner } = useLearnerContext();
  const { updatePaymentMethod } = usePaymentUpdate();

  if (!learner || learner.subscriptionPaymentFail !== true) return <></>;

  const exp = format(new Date(learner.subscriptionExpiration), "MM/dd/yy");
  return (
    <div className={clsx("mb-6 flex flex-col items-center", className)}>
      <div className="w-full rounded-lg border border-dm-error-500 bg-dm-error-100 p-4 font-bold">
        <i
          aria-hidden
          className="far fa-exclamation-triangle mr-3 text-dm-error-500"
        />
        Your payment method has failed.{" "}
        <Button type="link" onClick={updatePaymentMethod} className="!py-0">
          Update your payment information
        </Button>
        {` by ${exp} to avoid losing access to your subscription.`}
      </div>
    </div>
  );
};
