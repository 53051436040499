import {
  createContext,
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormattedLearnerSubscriptionPlan, Learner } from "../../types";
import { noop } from "lodash";
import { useDMQuery } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import {
  homepageScrollToPlansEvent,
  homepageSignupPaymentModalEvent,
} from "../../analytics/events";

const SignupContext = createContext<{
  plansRef: RefObject<HTMLElement> | null;
  plans: FormattedLearnerSubscriptionPlan[] | undefined;
  selectedPlan: FormattedLearnerSubscriptionPlan | undefined;
  setSelectedPlan: (plan: FormattedLearnerSubscriptionPlan) => void;
  startTrialText: string;
  showSignup: boolean;
  setShowSignup: (show: boolean) => void;
  scrollToPlans: (locationOnPage: string) => void;
  accountType: "parent" | "learner" | undefined;
  setAccountType: (accountType: "parent" | "learner" | undefined) => void;
  numLearners: number;
  setNumLearners: (numLearners: number) => void;
  showLogin: boolean;
  setShowLogin: (show: boolean) => void;
  showPromoPrice: boolean;
}>({
  plansRef: { current: null },
  plans: undefined,
  selectedPlan: undefined,
  setSelectedPlan: noop,
  startTrialText: "",
  showSignup: false,
  setShowSignup: noop,
  scrollToPlans: noop,
  accountType: undefined,
  setAccountType: noop,
  numLearners: 1,
  setNumLearners: noop,
  setShowLogin: noop,
  showLogin: false,
  showPromoPrice: false,
});

export function useHomepageSignupContext() {
  const ctx = useContext(SignupContext);
  return {
    ...ctx,
    // If someone sets a quantity and then signs up as a learner, only charge them for 1 learner
    numLearners: ctx.accountType === "learner" ? 1 : ctx.numLearners,
    numLearnersError: ctx.accountType === "learner" && ctx.numLearners > 1,
  };
}

export const HomepageSignupContextProvider: React.FC<
  PropsWithChildren<{
    plansRef: RefObject<HTMLElement> | null;
    initialAccountType?: "parent" | "learner";
    showPromoPrice?: boolean;
  }>
> = ({ plansRef, children, initialAccountType, showPromoPrice = false }) => {
  const { track } = useLearnerAnalytics();
  const user: Learner | null = JSON.parse(
    localStorage.getItem("user") || "null"
  );
  const [searchParams] = useSearchParams();
  const { data: plansData } = useDMQuery<{
    plans: FormattedLearnerSubscriptionPlan[];
  }>({
    path: "/payments/subscriptions/plans/list/published",
    queryOptions: { refetchOnWindowFocus: false },
  });
  const [selectedPlan, setSelectedPlan] = useState<
    FormattedLearnerSubscriptionPlan | undefined
  >(undefined);

  const startTrialText = !plansData?.plans
    ? ""
    : (plansData?.plans[0].trialLength ?? 0) > 0
    ? `Start ${plansData?.plans[0].trialLength}-Day Free Trial`
    : "Get Started";

  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);

  const [accountType, setAccountType] = useState<
    "parent" | "learner" | undefined
  >(initialAccountType);

  const [numLearners, setNumLearners] = useState<number>(
    user?.subscriptionQuantity || 1
  );

  const scrollToPlans = (locationOnPage: string) => {
    plansRef?.current?.scrollIntoView({ behavior: "smooth" });
    track(homepageScrollToPlansEvent(locationOnPage));
  };

  const trackedSetShowSignup = (show: boolean) => {
    setShowSignup(show);
    track(homepageSignupPaymentModalEvent(show));
  };

  useEffect(() => {
    if (!plansData) {
      return;
    }
    if (searchParams.has("resume")) {
      let resumeData: any = {};
      try {
        resumeData = JSON.parse(
          window.atob(decodeURIComponent(searchParams.get("resume") || ""))
        );
      } catch (e) {
        // Ignore
      }

      if (user) {
        setAccountType(
          user?.entitlements.includes("parent") ? "parent" : "learner"
        );
      } else if (resumeData.accountType) {
        setAccountType(resumeData.accountType);
      }

      if (resumeData.selectedPlan) {
        setSelectedPlan(
          plansData?.plans.find((plan) => plan._id === resumeData.selectedPlan)
        );
      } else {
        setSelectedPlan(plansData.plans[0]);
      }
      if (resumeData.numLearners) {
        setNumLearners(resumeData.numLearners);
      }
      setShowSignup(true);
    } else {
      setSelectedPlan(plansData.plans[0]);
    }
    if (user && searchParams.has("paid")) {
      setShowSignup(true);
    }
    // Only want this to run on first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansData]);

  return (
    <SignupContext.Provider
      value={{
        plansRef,
        plans: plansData?.plans,
        selectedPlan,
        setSelectedPlan,
        startTrialText,
        showSignup,
        setShowSignup: trackedSetShowSignup,
        scrollToPlans,
        accountType,
        setAccountType,
        numLearners,
        setNumLearners,
        setShowLogin,
        showLogin,
        showPromoPrice,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};
