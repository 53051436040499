import { noop } from "lodash";
import md5 from "md5";
const randOrd = (window as any).randOrd;

/**
 * Encapsulates the process of generating timed problems into an easy to use api.
 * Usage:
 * ```
 *  const g = new TimedProblemGenerator(moduleName); // Initialize the class
 *  await g.init(); // Get initialize async functions on class init, so make sure to do it now.
 *  const problem = g.make(); // Start generating your problems
 * ```
 */
export class TimedProblemGenerator {
  /** Name of the module which we will be generating files for. */
  private moduleName: string;
  private code: string;

  /** The problem generator function for the class. */
  private createProblem: () => TimedProblemType | void;

  /**
   * Initialize the generator by defining the module which it is generating problems for.
   * Note in the database timed module _ids are predicated with 'timed/${moduleName}'.
   * @param moduleName The _id of the custom module file to load.
   */
  constructor(moduleName: string, code: string) {
    this.moduleName = "timed/".concat(moduleName);
    this.code = code;
    this.createProblem = noop;
  }

  /**
   * Performs an md5 hash of the problem. This is used to define as a unique problem identifier.
   * @param problem The problem fields to hash.
   */
  private static hash(
    problem: Pick<
      TimedProblemType,
      | "question"
      | "questionText"
      | "questionTitle"
      | "explanation"
      | "choices"
      | "theAnswer"
      | "script"
      | "script2"
    >
  ): string {
    return md5(JSON.stringify(problem));
  }

  /**
   * The initialize function is responsible for evaluating the javascript file while ensuring the
   * appropriate functions and variables are available in it's scope. The resulting generator
   * function is bound to the createProblem method of the TimedProblemGenerator.
   */
  public init() {
    if (!this.code) {
      throw new Error(`Module ${this.moduleName} not found`);
    }

    // The function which is defined by calling `eval(code)`
    let createProblem: () =>
      | {
          script?: string;
          script2?: string;
          scriptInput?: any;
          scriptInput2?: any;
        }
      | undefined;

    // Define those scope variables required to bind createProblem.
    let question: string | undefined;
    let questionText: string | undefined;
    let questionTitle: string | undefined;
    let explanation: string[] = [];
    let choices: string[] = [];
    let theAnswer = 0;
    let doNotRandomize = false;
    let questionMathJax = true;
    let choicesMathJax = true;
    let showImage = false;
    let showImage2 = false;
    let questionSize = "1em";
    let questionMarginBot = "0px";
    let questionTextMarginBot = "0px";
    let choicesSize = "1.1em";
    let expMargin = "10px";
    let expSize = "1em";
    let script: string | undefined;
    let script2: string | undefined;
    let scriptInput: any;
    let scriptInput2: any;

    // Executing the custom javascript may leave the default parameters with values. It has been observed in some cases that the non-initialized result after
    // one call resulted in undesirable behavior on subsequent calls. So declare this 'scope' function To reset all values to default.
    const setDefaults = () => {
      question = undefined;
      questionText = undefined;
      questionTitle = undefined;
      explanation = [];
      choices = [];
      theAnswer = 0;
      doNotRandomize = false;
      questionMathJax = true;
      choicesMathJax = true;
      showImage = false;
      showImage2 = false;
      questionSize = "1em";
      questionMarginBot = "0px";
      questionTextMarginBot = "0px";
      choicesSize = "1.1em";
      expMargin = "10px";
      expSize = "1em";
      script = undefined;
      script2 = undefined;
      scriptInput = undefined;
      scriptInput2 = undefined;
    };

    // Parse the javascript, defining the above in-memory props.
    // eslint-disable-next-line no-eval
    eval(this.code);

    // Bind the function which is used to generate problems to the class
    this.createProblem = function () {
      // Clear any scope variables floating around by resetting them to default.
      setDefaults();

      // Generate the problem
      let duplicateChoices = false;
      do {
        duplicateChoices = false;
        const obj = createProblem(); // obj could hold a script to run
        for (let i = 0; i < choices.length - 1; i++) {
          for (let j = i + 1; j < choices.length; j++) {
            if (choices[i] === choices[j]) {
              duplicateChoices = true;
              break;
            }
          }
        }
        if (duplicateChoices) {
          explanation = [];
          choices = [];
        }
        script = obj?.script?.toString();
        script2 = obj?.script2?.toString();
        scriptInput = obj?.scriptInput;
        scriptInput2 = obj?.scriptInput2;
      } while (duplicateChoices);

      // Important to generate the hash prior to randomization of the print sequence
      const hash = TimedProblemGenerator.hash({
        question,
        questionText,
        questionTitle,
        explanation,
        choices,
        theAnswer,
        script,
        script2,
      });

      // Sort the choices in a random order, so the first is not always correct
      if (!doNotRandomize) {
        // eslint-disable-next-line prefer-const
        let correct = choices[theAnswer].valueOf();
        choices.sort(randOrd);
        choices.every((val, key) => {
          if (val === correct) {
            theAnswer = key;
            return false;
          }
          return true;
        });
      }

      // Return the generated problem data.
      return {
        id: hash,
        type: "timed",
        question,
        questionText,
        questionTitle,
        explanation,
        choices,
        theAnswer,
        questionMathJax,
        choicesMathJax,
        showImage,
        showImage2,
        questionSize,
        questionMarginBot,
        questionTextMarginBot,
        choicesSize,
        expMargin,
        expSize,
        script,
        script2,
        scriptInput,
        scriptInput2,
      };
    };
  }

  /**
   * Generates a Timed Problem.
   */
  public make() {
    return this.createProblem();
  }
}

/**
 * Describes the data of a single generated problem as produced by the generator.
 */
export type TimedProblemType = {
  /** The unique id of the problem, generated by hashing the problem characteristics. */
  id: string;
  /** Problem type */
  type: string;
  /** The question to solve. */
  question?: string;
  /** Additional question text of the question to display. */
  questionText?: string;
  /** The question title. */
  questionTitle?: string;
  /** An explanation of the problem scope. */
  explanation: string[];
  /** The array of possible choices. */
  choices: string[];
  /** The index of the choices array which represents the correct answer. */
  theAnswer: number;
  /** Boolean which controls the problem rendering on the client. */
  questionMathJax: boolean;
  /** Boolean which controls the problem rendering on the client. */
  choicesMathJax: boolean;
  /** Boolean which controls the problem rendering on the client. */
  showImage: boolean;
  /** Boolean which controls the problem rendering on the client. */
  showImage2: boolean;
  /** Format setting which controls the problem rendering on the client. */
  questionSize: string;
  /** Format setting which controls the problem rendering on the client. */
  questionMarginBot: string;
  /** Format setting which controls the problem rendering on the client. */
  questionTextMarginBot: string;
  /** Format setting which controls the problem rendering on the client. */
  choicesSize: string;
  /** Format setting which controls the problem rendering on the client. */
  expMargin: string;
  /** Format setting which controls the problem rendering on the client. */
  expSize: string;
  /** An optional script which should be executed prior to rendering the problem. */
  script?: string;
  /** An optional script which should be executed when a choice is clicked. */
  script2?: string;
  /** The input for script */
  scriptInput?: any;
  /** The input for script2 */
  scriptInput2?: any;
};

/**
 * Generates an array of timed problems, prints each problem to the console.
 * This mirrors the timed module generator on the backend.
 * Algorithm ensures we never repeat the same problem in any of the past 10 problems.
 * If 100 iterations pass without finding an entry meeting these rules it will exit and return any problem.
 * @param sk The skillcode (timed module) for which to generator problems.
 * @param code The string with the script to generate the problem.
 * @param numProblems The number of problems to generate
 * @return An array timed problems which the student must solve.
 */
export function generateTimedProblems(
  sk: string,
  code: string,
  numProblems: number
): TimedProblemType[] {
  const problems: TimedProblemType[] = [];
  const generator = new TimedProblemGenerator(sk, code);
  generator.init();

  console.log(`Here are ${numProblems} test problems:`);

  for (let i = 0; i < numProblems; i++) {
    if (i === 0) {
      const problem = generator.make();
      if (problem) problems[i] = problem;
    } else {
      let nextProblem: TimedProblemType | void;
      let j = 0;
      do {
        nextProblem = generator.make();
        j++;
      } while (
        j <= 100 &&
        nextProblem &&
        hasRepeatProblem(problems, i, nextProblem.id)
      );
      if (nextProblem) problems[i] = nextProblem;
    }
    console.log(problems[i]);
  }

  return problems;
}

/**
 * Function is used as a termination clause of the generate timed skills while loop.
 * If any of the last 10 problems is a duplicate this will return true.
 * @param problems Array of problems processed so far.
 * @param index Current index of the problem id.
 * @param id The unique hash of the problem.
 * @return When true if a duplicate problem is found in the last 10 (cues while loop to iterate).
 */
function hasRepeatProblem(
  problems: TimedProblemType[],
  index: number,
  id: string
): boolean {
  for (let i = index; i === Math.max(index - 10, 0); i--) {
    if (problems[i].id === id) {
      return true;
    }
  }
  return false;
}
