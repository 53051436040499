import * as Sentry from "@sentry/react";

/** Checks if a specific file name is included in the stacktrace of a Sentry error event */
export function checkFilename(event: Sentry.ErrorEvent, filename: string) {
  const valueWithFilename = event.exception?.values?.find((value) => {
    const frameWithFilename = value.stacktrace?.frames?.find(
      (frame) => frame.filename === filename
    );
    return frameWithFilename !== undefined;
  });

  return valueWithFilename !== undefined;
}
