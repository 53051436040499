import React, { useState } from "react";
import { Learner } from "../../types";
import { useToggleChallengeMutation } from "../../utils";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import Modal from "../../../student/components/generic/Modal";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";
import { useParentContext } from "../../contexts/ParentContext";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import FlagIcon from "../../../shared/icons/FlagIcon";
import { DMToggleDoubleLabel } from "../../../manager/components/DMToggleDoubleLabel";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { noop } from "lodash";

type Props = {
  learner: Learner | undefined;
  parentLearner?: Learner;
  parentOverride?: boolean;
};

const ToggleChallengeMode = (props: Props) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] =
    React.useState<boolean>(false);
  const toggleChallengeMode = useToggleChallengeMutation(setUpdating);
  const learnerContext = useLearnerContext();
  const parentContext = useParentContext();
  const toastContext = useDeltaToastContext();
  const isLearnerUser = props.learner?.entitlements.includes("learner");

  if (props.learner === undefined || !isLearnerUser) {
    return (
      <DMToggleDoubleLabel
        onLabel="On"
        offLabel="Off"
        onChange={noop}
        checked={false}
        disabled
      />
    );
  }

  const learnerId = props.learner._id;
  const status = getLearnerAccountStatus(props.learner);
  const showModal =
    props.learner.level === "easy"
      ? !learnerContext.learner?.challengeModalsState?.hasSeenOnLevelModal
      : !learnerContext.learner?.challengeModalsState?.hasSeenOffLevelModal;

  const modalTitle = props.parentLearner
    ? props.learner.level === "easy"
      ? `Active Challenge Mode for ${props.learner.first}?`
      : `Turn Challenge Mode off for ${props.learner.first}?`
    : props.learner.level === "easy"
    ? "Active Challenge Mode?"
    : "Turn Off Challenge Mode";

  const modalBody =
    props.learner.level === "easy" ? (
      <>
        Most content will be the same, but <b>new sections</b> will now show
        fewer basic problems and more challenging problems.
      </>
    ) : (
      <>
        This will lower the overall difficulty of new sections and courses, but
        it won&apos;t affect sections already started on Challenge Mode.
      </>
    );

  const filePath = `/images/learner/challenge/${
    props.learner.level === "easy" ? "deltie-light-bulb" : "deltie-reading"
  }`;

  return (
    <>
      <Modal
        visible={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        body={
          <div className="flex flex-col items-center gap-10">
            <ResponsiveImage
              className="mb-4 max-w-56 object-contain"
              srcs={[
                getFilePath(`${filePath}.png`),
                getFilePath(`${filePath}@2x.png`),
              ]}
              alt={
                props.learner.level === "easy"
                  ? "Deltie pointing at lightbulb"
                  : "Deltie reading a book"
              }
            />

            <div className="w-full">
              <h5 className="text-2xl font-bold leading-10">{modalTitle}</h5>
              <p className="mt-4 leading-[26px]">{modalBody}</p>
            </div>
          </div>
        }
        onConfirm={() => {
          toggleChallengeMode(learnerId);
          setShowConfirmModal(false);
        }}
        confirmButtonText={
          props.learner.level === "easy"
            ? "Yes, Active Challenge Mode"
            : "Yes, Turn Challenge Mode Off"
        }
        secondaryOnClick={() => setShowConfirmModal(false)}
        secondaryButtonText="Cancel"
      />
      <DMToggleDoubleLabel
        onLabel="On"
        offLabel="Off"
        checked={props.learner.level === "hard"}
        onChange={() => {
          if (showModal) {
            setShowConfirmModal(true);
          } else {
            toastContext.addToast(
              {
                status: "None",
                darkMode: true,
                id: "challenge-mode-set",
                message: (
                  <div className="svg-white">
                    <FlagIcon /> Challenge Mode has been{" "}
                    {props.learner?.level === "easy" ? "set" : "turned off"}.
                  </div>
                ),
              },
              true
            );
            toggleChallengeMode(learnerId);
          }
        }}
        disabled={
          status !== "active" ||
          updating ||
          parentContext.isLoadingLearners ||
          (!props.parentLearner &&
            props.learner.challengeModalsState?.parentSetLevel &&
            !props.parentOverride)
        }
      />
      {!props.parentLearner &&
        props.learner.challengeModalsState?.parentSetLevel &&
        !props.parentOverride && (
          <p className="mt-2 text-xs leading-4">
            This is disabled because your parent/guardian has turned challenge
            mode on.
          </p>
        )}
    </>
  );
};

export default ToggleChallengeMode;
