import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import slugify from "slugify";
import AddAdministrator from "./AddAdministrator";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { REACT_APP_ADMIN_LINK, executeQuery, useDMQuery } from "../../utils";
import { getAdminParams } from "../utils";
import clsx from "clsx";
import DeltaMathConfirmation from "../../shared/DeltaMathConfirmation";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { DemoMode } from "../../utils/demo";
import TrashIcon from "../../shared/icons/TrashIcon";
import { IconButton } from "../../student/components/generic/IconButton";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function Index({ demoMode = false }) {
  const params = useParams();
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const adminParams = getAdminParams();
  const [admins, setAdmins] = React.useState<any>();
  const adminsRef = useRef(admins);
  const [districtID, setDistrictId] = useState("");
  const districtIDRef = useRef(districtID);

  const userContext = useUserContext();

  useEffect(() => {
    adminsRef.current = admins;
  }, [admins]);

  const [userToDelete, setUserToDelete] = useState({
    _id: "",
    first: "",
    last: "",
    account_type: "",
    districtID: "",
    email: "",
    preferences: {
      showActiveSchools: "",
    },
    schoolIDs: [],
    showDeleteButton: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [primaryAdmin, setPrimaryAdmin] = useState<number>(0);

  const { data: adminsData } = useDMQuery({
    path: "/admin_new/data/admins",
    cacheKey: "admin-data",
    params: adminParams,
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const changePrimaryAdmin = useMutation(
    async (requestBody: { newAdminId: string; districtID: string }) =>
      await executeQuery({
        path: "/admin_new/manage/update_district_admin",
        method: "post",
        requestBody,
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["admin-data"]);
        toastContext.addToast({
          status: res.success ? "Success" : "Error",
          message: res.message,
        });
      },
      onError: (e) => {
        console.log(e);
        toastContext.addToast({
          status: "Error",
          message: "There was an error with the request",
        });
      },
    }
  );

  const { data: districtData, refetch: districtDataRefetch } = useDMQuery({
    path: "/admin_new/data/district",
    cacheKey: "district-data",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      //enabled: false, // We only want to make this request if we're a district admin, we check that in a useEffect below,
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const id = user.admin ? user.admin : user._id;
    if (adminsData && adminsData.length > 0) {
      const findCurrentUser = adminsData.filter(
        (user: { _id: string }) => user._id === id
      );
      if (findCurrentUser && findCurrentUser.length > 0) {
        districtDataRefetch();
      }
    }
    if (adminsData) {
      const results = adminsData.filter(
        (a: { account_type: string }) =>
          a.account_type === "super_district" || a.account_type === "district"
      );
      results.forEach((a: { account_type: string }, index: number) => {
        if (a.account_type !== "super_district") {
          results[index]["showDeleteButton"] = true;
        }
      });
      setPrimaryAdmin(
        results.findIndex(
          (a: { account_type: string }) => a.account_type === "super_district"
        )
      );
      setAdmins(results);
    }
  }, [adminsData]);

  useEffect(() => {
    if (districtData) {
      setDistrictId(districtData.districtID);
    }
  }, [districtData]);

  useEffect(() => {
    districtIDRef.current = districtID;
  }, [districtID]);

  const columns = React.useMemo(() => {
    const columnsDefinition: {
      Header: string;
      accessor: string;
      align: string;
      Cell?: (props: any) => JSX.Element;
      disableSortBy?: boolean;
    }[] = [
      {
        Header: "First Name",
        accessor: "first",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_first" />;
        },
      },
      {
        Header: "Last Name",
        accessor: "last",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_last" />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_email" />;
        },
      },
    ];
    //TODO: get rid of CST check
    if (
      (userContext.state.decodedJwt?.data?.isManager ||
        localStorage.getItem("customer_service_token")) &&
      !demoMode
    ) {
      columnsDefinition.push({
        Header: "Primary Admin?",
        accessor: "primary",
        align: "center",
        Cell: (props: any) => (
          <input
            type="radio"
            value={props.row.index}
            checked={primaryAdmin === parseInt(props.row.index, 10)}
            onChange={(e) => {
              setPrimaryAdmin(parseInt(e.target.value, 10));
              changePrimaryAdmin.mutate({
                newAdminId: adminsRef.current[parseInt(e.target.value)]?._id,
                districtID: districtIDRef.current,
              });
            }}
          />
        ),
      });
    }

    columnsDefinition.push({
      Header: "",
      accessor: "showDeleteButton",
      disableSortBy: true,
      align: "right",
      Cell: (props: { row: { original: any } }) => (
        <>
          {props.row.original.showDeleteButton && (
            <IconButton
              onClick={() => {
                setShowDeleteConfirmation(true);
                setUserToDelete(props.row.original);
              }}
              icon={<TrashIcon />}
              aria-label={"Delete"}
            />
          )}
        </>
      ),
    });
    return columnsDefinition;
  }, [changePrimaryAdmin, primaryAdmin, demoMode]);

  const removeDistrictAdmin = useMutation(
    () => {
      return axios.delete(
        deltamathAPI() + `/admin_new/manage/remove/${userToDelete._id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin-data"]);
        setShowDeleteConfirmation(false);
        toastContext.addToast({
          status: "Success",
          message: "District Admin Account deleted.",
        });
      },
      onError: () => {
        setShowDeleteConfirmation(false);
        toastContext.addToast({
          status: "Error",
          message: "There was an error with the request",
        });
        setUserToDelete({
          _id: "",
          first: "",
          last: "",
          account_type: "",
          districtID: "",
          email: "",
          preferences: {
            showActiveSchools: "",
          },
          schoolIDs: [],
          showDeleteButton: "",
        });
      },
    }
  );

  const tabs = [
    {
      name: "Current District Admins",
      roles: ["district", "super_district"],
      slug: "current-admins",
    },
    {
      name: "Invite District Admins",
      roles: ["district", "super_district", "school", "super_school"],
      slug: "invite-admins",
    },
  ];

  return (
    <>
      {showDeleteConfirmation && userToDelete && (
        <DeltaMathConfirmation
          title={`Remove Account`}
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode value={userToDelete.first} type="person_first" />{" "}
              <DemoMode value={userToDelete.last} type="person_last" />
              &rsquo;s account? This user will be removed from the district and
              will no longer have access to the districts&apos;s data.
            </>
          }
          confirm="Delete"
          confirmAction={removeDistrictAdmin.mutate}
          cancel="Cancel"
          cancelAction={() => {
            setShowDeleteConfirmation(false);
            setUserToDelete({
              _id: "",
              first: "",
              last: "",
              account_type: "",
              districtID: "",
              email: "",
              preferences: {
                showActiveSchools: "",
              },
              schoolIDs: [],
              showDeleteButton: "",
            });
          }}
        />
      )}

      {/* {districtData && (
        <h2 className="border-b-2 border-solid border-slate-400 text-lg font-medium leading-6 text-gray-900">
          <DemoMode value={districtData?.districtName} type="city" /> District
          Administrators
        </h2>
      )} */}
      <div className="border border-b-2 bg-white">
        {districtData?.districtName && (
          <h2 className="px-6 py-8 font-serif text-2xl font-bold">
            <DemoMode value={districtData?.districtName} type="city" /> District
            Administrators
          </h2>
        )}
        <div className="px-6">
          <nav
            className="-mb-px flex justify-between sm:justify-normal sm:space-x-20"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={`${REACT_APP_ADMIN_LINK}/district/${slugify(tab.slug, {
                  lower: true,
                })}`}
                replace
                className={clsx(
                  slugify(tab.slug, { lower: true }) === params.selected_tab
                    ? "border-dm-brand-blue-500"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "border-b-4 px-1 py-4 text-center text-sm font-medium"
                )}
                aria-current={
                  slugify(tab.slug, { lower: true }) === params.selected_tab
                    ? "page"
                    : undefined
                }
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className="p-6">
        {params.selected_tab === "current-admins" && (
          <>
            {admins && admins.length > 0 && (
              <div className="flow-root">
                {" "}
                <DeltaMathTable
                  columns={columns}
                  data={admins}
                  options={{
                    initialState: {
                      sortBy: [
                        {
                          id: "last",
                          desc: false,
                        },
                      ],
                    },
                  }}
                />
              </div>
            )}
          </>
        )}
        {params.selected_tab === "invite-admins" && <AddAdministrator />}
      </div>
    </>
  );
}
