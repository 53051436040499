import { useNavigate } from "react-router-dom";
import { executeQuery, REACT_APP_LEARNER_LINK } from "../../utils";
import { Learner } from "../types";
import { useEffect, useState } from "react";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import { useQuery } from "react-query";
import { useLearnerContext } from "../contexts/LearnerContext";
import { useChildLearnersQuery } from "../utils/useChildLearnersQuery";

export const CheckStatusPage: React.FC = () => {
  const { learner: accountOwner } = useLearnerContext();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const { refetch: refetchChildLearners } = useChildLearnersQuery();

  const { refetch: fetchSessionStatus } = useQuery<{
    status: "waiting" | "success";
    user?: Learner;
  }>(
    "sessionStatus",
    () =>
      executeQuery({
        path: "/payments/subscriptions/session/status",
      }),
    { enabled: false }
  );

  useEffect(() => {
    let t: number;
    const checkSubscriptionStatus = async () => {
      const { data } = await fetchSessionStatus();
      if (data?.user && data?.status === "success") {
        localStorage.setItem("user", JSON.stringify(data.user));
        setIsSuccess(true);
      } else {
        t = window.setTimeout(() => {
          checkSubscriptionStatus();
        }, 1000);
      }
    };
    checkSubscriptionStatus();
    return () => clearTimeout(t);

    // Only run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountOwner && isSuccess) {
      if (!accountOwner.entitlements.includes("parent")) {
        navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
      } else {
        (async () => {
          const { data } = await refetchChildLearners();

          navigate(
            data?.learners.length
              ? `${REACT_APP_LEARNER_LINK}/parent/confirm-learners`
              : `${REACT_APP_LEARNER_LINK}/parent/learners/start`
          );
        })();
      }
    }
  }, [accountOwner, isSuccess, navigate, refetchChildLearners]);

  return (
    <main className="flex h-dvh w-full items-center justify-center">
      <DmLoadingSpinner message="" />
    </main>
  );
};
