import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../shared/contexts/UserContext";
import { useLogin } from "../../utils/auth/login";
import { processLoginResponse } from "../../utils/auth/processLoginResponse";
import { GoogleLoginButton } from "./GoogleLoginButton";
import { LoginAccountType } from "../../shared/types";

declare global {
  interface Window {
    google: any;
  }
}

export type preLoginResponseType = {
  number: number;
  token: string;
};

export type loginRequestType = {
  email: string;
  password: string;
  prelogin_factor: number;
  prelogin_token: string;
  account_type?: LoginAccountType;
};

export default function Login({
  setLoggedIn,
  accountType,
}: {
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  accountType: LoginAccountType;
}) {
  const userContext = useUserContext();
  const queryClient = useQueryClient();
  const login = useLogin();

  const [loginLoading, setLoginLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState<string | null>(null);

  const navigate = useNavigate();

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    setLoginLoading(true);
    setFormError(null);
    (async () => {
      try {
        const { success } = await login(
          email,
          password,
          accountType ?? "admin"
        );
        setLoggedIn(success);
        setLoginLoading(false);
        navigate("");
      } catch (e: any) {
        setLoggedIn(false);
        setLoginLoading(false);
        setFormError(e.message);
      }
    })();
  };

  // This needs useCallback to prevent the Google button from re-rendering
  // every time a form field changes
  const handleGoogleLogin = useCallback(
    (loginData: unknown) => {
      const { success } = processLoginResponse(loginData, userContext);
      setLoggedIn(success);
      navigate("");
    },
    [navigate, setLoggedIn, userContext]
  );

  return (
    <div className="flex min-h-full w-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Login
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={submitForm}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                {accountType === "learner" ? "Username" : "Email address"}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type={accountType === "learner" ? "text" : "email"}
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loginLoading}
                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
              >
                Sign in
              </button>
              {loginLoading && <div className="mt-4">Logging you in...</div>}
              {formError && (
                <p className="mt-4 text-dm-error-500">{formError}</p>
              )}
            </div>
          </form>

          <div className="mt-6 text-center">
            OR
            <GoogleLoginButton onLogin={handleGoogleLogin} />
          </div>
        </div>
      </div>
    </div>
  );
}
