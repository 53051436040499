export const AddItemButton: React.FC<{ onClick: () => void }> = ({
  onClick,
  children,
}) => (
  <button
    className="flex items-center gap-2 text-dm-brand-blue-500 hover:text-dm-brand-blue-600"
    type="button"
    onClick={onClick}
  >
    <i className="far fa-plus" />
    {children}
  </button>
);
