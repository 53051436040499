import clsx from "clsx";
import { ReactNode, useState } from "react";

type Props = {
  message?: string | ReactNode;
  children: ReactNode;
  options?: {
    aligned?: "default" | "center";
    inline?: boolean;
    raise?: string;
    tooltipRight?: boolean;
    maxWidth?: number;
  };
  className?: string;
  ariaLabel?: string;
};

export const Infotip = (props: Props) => {
  const [isShowing, setIsShowing] = useState(false);

  const showInfotip = () => setIsShowing(true);
  const hideInfotip = () => setIsShowing(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      setIsShowing((isShowing) => !isShowing);
    }
  };

  if (!props.message) return <>{props.children}</>;

  return (
    <div className="inline-block">
      <div
        className={clsx(
          "relative overflow-visible",
          props.options && props.options.inline ? "inline" : "flex flex-col",
          !props.options || props.options.aligned === "center"
            ? "items-center"
            : ""
        )}
        onMouseEnter={showInfotip}
        onMouseLeave={hideInfotip}
      >
        <button
          className="min-h-7 min-w-7 cursor-default"
          onKeyDown={handleKeyDown}
          onBlur={hideInfotip}
          aria-label={props.ariaLabel}
          aria-expanded={isShowing}
        >
          {props.children}
        </button>
        <div
          className={clsx(
            isShowing ? "flex" : "hidden",
            props?.options?.raise ? props.options.raise : "mb-6",
            `absolute bottom-0 z-50 flex-col items-center`,
            props.options?.tooltipRight ? "left-7 top-0" : ""
          )}
          style={{
            width: props.options?.maxWidth
              ? `${props.options.maxWidth}px`
              : undefined,
          }}
        >
          <span
            className={clsx(
              "relative z-50 rounded-md bg-dm-charcoal-600 p-2 text-xs leading-5 text-white shadow-lg",
              props.options?.maxWidth
                ? "whitespace-normal"
                : "whitespace-nowrap"
            )}
          >
            {props.message}
          </span>
          <div className="z-50 -mt-2 h-3 w-3 rotate-45 bg-dm-charcoal-600"></div>
        </div>
      </div>
    </div>
  );
};
