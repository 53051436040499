import { LearnerSubscriptionDetails } from "../../types";
import { AccountsTableWide } from "./AccountsTableWide";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { AccountsTableNarrow } from "./AccountsTableNarrow";
import { useLearnerFeature } from "../../utils/useLearnerFeature";

export const AccountsTable: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
  learnerId?: string;
}> = ({ subscription, learnerId }) => {
  const challengeModeFeatureFlag = useLearnerFeature("enableChallengeMode");

  if (!subscription) {
    return (
      <div className="flex h-52 w-full items-center justify-center">
        <DmLoadingSpinner message="" />
      </div>
    );
  }

  return (
    <>
      <AccountsTableNarrow
        subscription={subscription}
        enableChallengeMode={challengeModeFeatureFlag}
      />
      <AccountsTableWide
        subscription={subscription}
        enableChallengeMode={challengeModeFeatureFlag}
      />
    </>
  );
};
