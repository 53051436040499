import { PropsWithChildren } from "react";
import { LearnerPage } from "../Layouts/LearnerPage";
import { ParentNavAndSidebar } from "../Parent/ParentNavAndSidebar";
import { PaymentErrorBanner } from "./PaymentErrorBanner";

export const ProfilePage: React.FC<
  PropsWithChildren<{ isParentPage?: boolean }>
> = ({ isParentPage = false, children }) => (
  <LearnerPage
    title="Profile"
    {...(isParentPage
      ? {
          sidebar: <ParentNavAndSidebar />,
          noDesktopSidebar: true,
        }
      : {})}
    dontTrackTime
    noPadding
    noPaymentErrorBanner
  >
    <div className="flex flex-col items-center gap-6 px-4 py-6 sm:px-6">
      <PaymentErrorBanner className="!mb-0 w-[1288px] max-w-full" />
      {children}
    </div>
  </LearnerPage>
);
