import { PropsWithChildren, ReactNode } from "react";

export const ProfileField: React.FC<
  PropsWithChildren<{
    label: string | ReactNode;
  }>
> = ({ label, children }) => (
  <div className="flex flex-col gap-1">
    <h3 className="text-sm font-bold">{label}</h3>
    <div className="flex flex-col items-start gap-1 text-sm">{children}</div>
  </div>
);
