import { useQuery } from "react-query";
import { executeQuery } from "../../../utils";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { viewedExperimentVariantEvent } from "../../analytics/events";
import { useCallback, useState } from "react";

const MIN_RETRACK_TIME = 1000; // 1 second

/** Hook to fetch learner experiments */
export function useLearnerExperiments(): {
  /** True if currently loading experiments */
  isLoading: boolean;
  /**
   * Checks if the user is in the experiment variant. **This will track that
   * the user viewed the experiment,** so be sure to call it lazily when
   * rendering the experimental feature.
   */
  isInExperiment: (experimentName: string, variantName: string) => boolean;
} {
  const user = JSON.parse(localStorage.getItem("user") || "null");
  const isLearner =
    user &&
    (user.entitlements?.includes("parent") ||
      user.entitlements?.includes("learner"));
  const { track } = useLearnerAnalytics();

  const { data, isLoading } = useQuery<{ experiments: Record<string, string> }>(
    "experiments/list/learner",
    () =>
      executeQuery({
        path: isLearner
          ? "/experiments/list/learner"
          : "/experiments/list/learner/anon",
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  );

  const [tracked, setTracked] = useState<Record<string, number>>({});

  const isInExperiment = useCallback(
    (experimentName: string, variantName: string) => {
      if (!data) {
        return false;
      }
      const userVariant = data.experiments[experimentName];

      // In cases where this fires multiple times in a row, we only want to
      // track it once
      const trackedKey = `${experimentName}:${userVariant}`;
      if (
        !tracked[trackedKey] ||
        Date.now() - tracked[trackedKey] > MIN_RETRACK_TIME
      ) {
        track(viewedExperimentVariantEvent(experimentName, userVariant));
        setTracked((prev) => ({ ...prev, [trackedKey]: Date.now() }));
      }

      return userVariant === variantName;
    },
    [data, track, tracked]
  );

  return {
    isLoading,
    isInExperiment,
  };
}
