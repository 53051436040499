import { useQuery } from "react-query";
import { LearnerSubscriptionDetails } from "../types";
import { executeQuery } from "../../utils";

export function useSubscription(): {
  subscription: LearnerSubscriptionDetails | undefined;
  isLoading: boolean;
  refetchSubscription: () => Promise<void>;
} {
  const {
    data: subscriptionData,
    isLoading,
    refetch: refetchSubscriptionData,
  } = useQuery<{
    subscription: LearnerSubscriptionDetails;
  }>(
    "/payments/subscriptions/active",
    () => executeQuery({ path: "/payments/subscriptions/active" }),
    { refetchOnWindowFocus: false }
  );

  return {
    subscription: subscriptionData?.subscription,
    isLoading,
    refetchSubscription: async () => {
      await refetchSubscriptionData();
    },
  };
}
