const FlagIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="challenge (1) 1">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.90047 4.79789C3.43825 4.19332 3.86005 2 5.4488 2C7.03755 2 7.45934 4.19332 5.99713 4.79789V5.529C11.5648 3.63093 14.2502 7.49736 18.5665 5.81019C18.918 5.6696 19.3117 5.93673 19.3117 6.31634V15.6661C19.3117 15.891 19.1711 16.1019 18.9602 16.1722C14.0815 18.0984 11.4383 13.9789 5.99713 16.0457V21.4517C5.99713 22.1687 4.90047 22.1687 4.90047 21.4517V4.79789ZM5.99713 6.69596V14.8787C6.98131 14.5413 7.88114 14.3866 8.71066 14.3585V6.10545C7.88114 6.14763 6.99537 6.31634 5.99713 6.69596ZM9.80732 14.3726C12.9848 14.5975 15.1922 16.2988 18.2151 15.2865V7.08963C15.0094 7.98945 12.7177 6.35852 9.80732 6.11951V14.3726ZM5.4488 3.09666C4.98483 3.09666 4.98483 3.79965 5.4488 3.79965C5.91277 3.79965 5.91277 3.09666 5.4488 3.09666Z"
          fill="#0C1116"
          stroke="#0C1116"
          strokeWidth="0.7"
          className={className}
        />
      </g>
    </svg>
  );
};

export default FlagIcon;
