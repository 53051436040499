import { format } from "date-fns";
import { ProfilePage } from "./ProfilePage";
import { ProfileSection } from "./ProfileSection";
import { ProfileField } from "./ProfileField";
import { ProfileFieldGroup } from "./ProfileFieldGroup";
import { Learner } from "../../types";
import { LearnerEditAccountButton } from "./LearnerEditAccountButton";
import { getLearnerAccountStatus } from "./getLearnerAccountStatus";
import { SubscriptionStatusPill } from "./SubscriptionStatusPill";
import Button from "../../../student/components/generic/button";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import Modal from "../../../student/components/generic/Modal";
import { useEffect, useState } from "react";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import ToggleChallengeMode from "./ToggleChallengeMode";
import { Infotip } from "../../../shared/Infotip";
import { useLearnerFeature } from "../../utils/useLearnerFeature";

export const LearnerProfile: React.FC<{ user: Learner }> = ({ user }) => {
  const toastContext = useDeltaToastContext();
  const status = getLearnerAccountStatus(user);
  const [showExpiredModal, setShowExpiredModal] = useState(false);

  const { mutateAsync: notifyParentMutation, isLoading: isNotifyingParent } =
    useMutation(() => {
      return axios.post(
        `${deltamathAPI()}/learner/data/notify/parent-reactivate`,
        "",
        withJsonHeader()
      );
    });

  const challengeModeFeatureFlag = useLearnerFeature("enableChallengeMode");

  const notifyParent = async () => {
    try {
      await notifyParentMutation();
      toastContext.addToast({
        message: "Email sent",
        status: "Success",
      });
      setShowExpiredModal(false);
    } catch (e: any) {
      toastContext.addToast({
        message:
          e.response.data.message || "Unable to send email to parent/caregiver",
        status: "Error",
      });
    }
  };

  useEffect(() => {
    if (!isSubscriptionActive(user)) {
      setShowExpiredModal(true);
    }
  }, [user]);

  return (
    <ProfilePage>
      <ProfileSection title="Account Information">
        <ProfileFieldGroup
          title="Your Account"
          className="relative sm:min-w-80"
        >
          <LearnerEditAccountButton
            className="absolute right-0 top-0"
            user={user}
          />
          <ProfileField label="Account Holder Name">
            {user.first} {user.last}
          </ProfileField>
          <ProfileField label="Account Holder Username/Email">
            {user.email}
          </ProfileField>
          <ProfileField label="Password">{"*".repeat(16)}</ProfileField>
          <ProfileField label="Account Type">Learner</ProfileField>
          {challengeModeFeatureFlag && (
            <ProfileField
              label={
                <>
                  Challenge Mode{" "}
                  <Infotip
                    options={{
                      tooltipRight: true,
                      maxWidth: 200,
                      aligned: "center",
                    }}
                    message="Courses too easy? Enable challenge mode to see fewer basic problems and more challenging problems. Most content will be the same in each section whether or not Challenge Mode is enabled."
                  >
                    <i
                      className="far fa-info-circle text-lg font-normal leading-none text-dm-gray-200"
                      aria-hidden="true"
                    ></i>
                  </Infotip>
                </>
              }
            >
              <ToggleChallengeMode learner={user} />
            </ProfileField>
          )}
          <ProfileField label="Learner Start Date">
            {format(new Date(user.createdAt), "MM'/'dd'/'yy")}
          </ProfileField>
          <ProfileField label="Status">
            <SubscriptionStatusPill status={status} />
            {(status === "canceled" || status === "inactive") && (
              <Button
                className="mt-4"
                onClick={notifyParent}
                isLoading={isNotifyingParent}
              >
                Email Parent/Caregiver to Activate Account
              </Button>
            )}
          </ProfileField>
        </ProfileFieldGroup>
      </ProfileSection>

      <Modal
        visible={showExpiredModal}
        onClose={() => setShowExpiredModal(false)}
        closeX
        body={
          <div>
            <h2 className="mb-6 text-center font-serif text-2xl font-bold text-dm-brand-blue-600">
              Your Subscription is Expired
            </h2>
            <p>
              Seems like your subscription is expired. Ask your parent or
              caregiver for help reactivating this account.
            </p>
          </div>
        }
        confirmButtonText="Email Parent to Activate Account"
        onConfirm={notifyParent}
        confirmDisabled={isNotifyingParent}
      />
    </ProfilePage>
  );
};
