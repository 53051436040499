export const UserWarningIcon: React.FC<{
  maskClassName: string;
  stroke: string;
}> = ({ maskClassName, stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.7778 6.55556C15.7778 8.51923 14.1859 10.1111 12.2222 10.1111C10.2585 10.1111 8.66667 8.51923 8.66667 6.55556C8.66667 4.59188 10.2585 3 12.2222 3C14.1859 3 15.7778 4.59188 15.7778 6.55556Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2222 12.7778C8.78578 12.7778 6 15.5636 6 19H18.4444C18.4444 15.5636 15.6587 12.7778 12.2222 12.7778Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.582 14.75V16V14.75ZM16.582 18.5H16.5882H16.582ZM12.2519 21H20.9121C21.8743 21 22.4758 19.9583 21.9947 19.125L17.6645 11.625C17.1834 10.7917 15.9806 10.7917 15.4995 11.625L11.1693 19.125C10.6882 19.9583 11.2896 21 12.2519 21Z"
      className={maskClassName}
    />
    <path
      d="M16.582 14.75V16M16.582 18.5H16.5882M12.2519 21H20.9121C21.8743 21 22.4758 19.9583 21.9947 19.125L17.6645 11.625C17.1834 10.7917 15.9806 10.7917 15.4995 11.625L11.1693 19.125C10.6882 19.9583 11.2896 21 12.2519 21Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
