import { FieldValues, UseFormRegister } from "react-hook-form";

interface Props<T extends FieldValues> {
  name: Parameters<UseFormRegister<T>>[0];
  register: UseFormRegister<T>;
  validation: Parameters<UseFormRegister<T>>[1];
  options: string[];
  disabled?: boolean;
}

export function SelectInput<T extends FieldValues>({
  name,
  register,
  validation,
  options,
  ...attrs
}: Props<T>) {
  return (
    <select
      className="h-10 rounded pl-3 pr-10 disabled:bg-dm-charcoal-100 disabled:text-dm-charcoal-500"
      id={name}
      {...attrs}
      {...register(name, validation)}
    >
      <option value="">Select an option</option>
      {options.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
}
