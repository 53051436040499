import clsx from "clsx";
import { Experiment } from "./types";
import { capitalize } from "lodash";

export const ExperimentState: React.FC<{ state: Experiment["state"] }> = ({
  state,
}) => (
  <span
    className={clsx(
      "rounded-full px-4 py-1",
      state === "draft" && "bg-yellow-600 text-white",
      state === "open" && "bg-green-700 text-white",
      state === "closed" && "bg-blue-800 text-white",
      state === "retired" && "bg-dm-gray-200 text-white"
    )}
  >
    {capitalize(state)}
  </span>
);
